import {
  AdludioTheme,
  Body1,
  Box,
  Dropzone,
  Grid,
  makeStyles,
} from '@adludio/components';
import React, { useEffect, useRef } from 'react';

import { CampaignDocument } from '../models/document';
import { FileTable } from '../components/FileTable';
import MissingFiles from '../assets/upload-file.svg';
import { RouteComponentProps } from '@reach/router';
import { UploadStatus } from '../components/UploadStatus';
import { acceptedFiletypes } from '../helpers/acceptedFiletypes';
import axios from 'axios';
import { useGetAssetsQuery } from '../generated/graphql';
import { useUpload } from '../helpers/useUpload';

interface FilesPageProps extends RouteComponentProps {
  campaignId: string;
}
const useStyles = makeStyles((theme: AdludioTheme) => ({
  fileTable: {
    minWidth: '83.3%',
    width: 'auto',
    maxWidth: '100%',
  },
  pageContent: {
    marginBottom: '2.5rem',
  },
}));

export const FilesPage = (props: FilesPageProps) => {
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [files, setFiles] = React.useState<File[]>([]);
  const filesRef = useRef(files);
  const [status, setStatus] = React.useState<boolean[]>([]);
  const [filesAwaiting, setFilesAwaiting] = React.useState<File[]>([]);
  const filesAwaitingRef = useRef(filesAwaiting);
  const [progress, setProgress] = React.useState<number[]>([]);
  const progressRef = useRef(progress);
  const cancelTokenSource = useRef(axios.CancelToken.source());
  const intervalRef = useRef<number | null>();

  const { fileTable, pageContent } = useStyles();

  const { data, refetch } = useGetAssetsQuery({
    variables: {
      campaignId: props.campaignId,
    },
  });

  const {
    uploadDocument,
    isUploading,
    status: fileStatus,
    uploadProgress,
  } = useUpload({
    reFetch: refetch,
    cancelToken: cancelTokenSource.current.token,
  });

  const handleFiles = (droppedFiles: File[]) => {
    const sorted = [...droppedFiles].sort((a, b) => a.size - b.size);
    if (filesAwaiting.length) {
      setFiles(files.concat(sorted));
      setProgress(
        progressRef.current.concat(Array(droppedFiles.length).fill(0))
      );
    } else {
      setFiles(sorted);
      setStatus([]);
      setProgress(Array(droppedFiles.length).fill(0));
    }
    setFilesAwaiting(filesAwaiting.concat(sorted));
  };
  const removeUpload = (index: number) => {
    const fileToBeRemoved = files[index];
    const newFiles = [...files.slice(0, index), ...files.slice(index + 1)];
    setFiles(newFiles);
    const newUploads = [
      ...progress.slice(0, index),
      ...progress.slice(index + 1),
    ];
    setProgress(newUploads);
    if (selectedFile === fileToBeRemoved) {
      cancelTokenSource.current.cancel();
    } else {
      setFilesAwaiting(
        filesAwaiting.filter((awaitingFile) => awaitingFile !== fileToBeRemoved)
      );
    }
  };

  const fakeUploadProgress = async () => {
    if (filesAwaitingRef.current.length > 1) {
      const newUploadProgress = [...progressRef.current];
      filesAwaitingRef.current.forEach((file, i) => {
        if (i === 0) return;
        const fileIndex = filesRef.current.indexOf(file);
        const fakeProgress =
          progressRef.current[fileIndex] + Math.random() * 0.5;
        if (fakeProgress > 5) return;
        newUploadProgress.splice(fileIndex, 1, fakeProgress);
      });
      setProgress(newUploadProgress);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }
  };

  useEffect(() => {
    progressRef.current = progress;
  }, [progress]);

  useEffect(() => {
    filesRef.current = files;
  }, [files]);

  useEffect(() => {
    filesAwaitingRef.current = filesAwaiting;
    if (filesAwaiting.length > 1) {
      intervalRef.current = window.setInterval(function () {
        fakeUploadProgress().catch(console.log);
      }, 4000);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesAwaiting]);

  React.useEffect(() => {
    if (
      !isUploading &&
      filesAwaiting.length &&
      selectedFile !== filesAwaiting[0]
    ) {
      cancelTokenSource.current = axios.CancelToken.source();
      setSelectedFile(filesAwaiting[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesAwaiting]);

  React.useEffect(() => {
    if (!selectedFile || isUploading) return;
    uploadDocument(selectedFile, props.campaignId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  React.useEffect(() => {
    const selectedFileIndex = filesRef.current.indexOf(selectedFile!);
    if (uploadProgress > progressRef.current[selectedFileIndex]) {
      const newUploadProgress = [...progressRef.current];
      newUploadProgress.splice(selectedFileIndex, 1, uploadProgress);
      setProgress(newUploadProgress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadProgress]);

  React.useEffect(() => {
    if (!isUploading && selectedFile) {
      setFilesAwaiting(filesAwaitingRef.current.slice(1));
      if (fileStatus !== null) {
        setStatus(status.concat(fileStatus));
        if (fileStatus) {
          const selectedFileIndex = filesRef.current.indexOf(selectedFile!);
          const newUploadProgress = [...progressRef.current];
          newUploadProgress.splice(selectedFileIndex, 1, 100);
          setProgress(newUploadProgress);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploading]);

  return (
    <Grid
      container
      item
      className={pageContent}
      xs={10}
      direction='row'
      justifyContent='center'
      alignItems='center'
    >
      <Grid
        container
        lg={10}
        md={10}
        sm={12}
        item
        justifyContent='flex-start'
        direction='row'
      >
        <Body1>Additional Files</Body1>
      </Grid>
      <Grid item container lg={10} md={10} sm={12}>
        <Box width='100%' paddingY='2rem' minHeight='8rem' textAlign='center'>
          <Dropzone
            numberOfFiles='multiple'
            setFiles={handleFiles as any} // Dropzone component expects a setState type but we're wrapping it
            files={files}
            accept={acceptedFiletypes}
          />
          <UploadStatus
            files={files}
            status={status}
            progress={progress}
            currentProgress={uploadProgress}
            removeUpload={removeUpload}
            currentIndex={selectedFile ? files.indexOf(selectedFile) : 0}
          />
        </Box>
      </Grid>
      <Grid
        item
        container
        className={fileTable}
        justifyContent='center'
        alignItems='center'
      >
        {data && data.getAssets!.length > 0 ? (
          <FileTable
            data={data?.getAssets as [CampaignDocument]}
            reFetch={refetch}
          />
        ) : (
          <Grid container justifyContent='center'>
            <Box width={'100%'}>
              <Body1 color='primary' align='center'>
                No files have been uploaded yet.
              </Body1>
              <Body1 color='primary' align='center'>
                To get started, drag and drop or browse files using the drop
                zone
              </Body1>
            </Box>
            <Box mt='3rem'>
              <img src={MissingFiles} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FilesPage;
