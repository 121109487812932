import React, { useState } from 'react';
import { AuthContext, SuccessAuthContextProps } from '@adludio/react-sso';

import {
  Body2,
  Grid,
  TextInput,
  useSnackbar,
  makeStyles, Link, AdludioTheme
} from '@adludio/components';

import { updateUser } from '../../../API';
import ProfileEdit from '../ProfileEdit';

const useStyles = makeStyles((theme: AdludioTheme) => ({
  userProfileDataContainer: {
    minHeight: '14rem',
    paddingBottom: '1rem'
  },
  changeEmailText: {
    color: theme.palette.grey[600]
  }
}));

export interface UserProfileProps {
  switchView: () => void;
}

const UserProfileEdit = (props: UserProfileProps) => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { auth: { user: currentUser, authToken } } = React.useContext(AuthContext) as SuccessAuthContextProps;
  const [user, setUser] = useState({ name: currentUser.name, jobTitle: currentUser.jobTitle ? currentUser.jobTitle : '' });

  function handleSubmit() {
    updateUser(authToken, currentUser.id, user)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          console.log(res.error);
          enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
        else {
          enqueueSnackbar('Change saved', { variant: 'success' });
          props.switchView();
        }
      })
      .catch(console.log);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setUser({ ...user, [e.currentTarget.name]: e.currentTarget.value });
  }

  return (
    <ProfileEdit handleSubmit={handleSubmit} switchView={props.switchView}>
      <Grid className={styles.userProfileDataContainer} item container justify='space-around' direction='column'>
        <TextInput variant='outlined' label='Name' name='name' value={user.name} onChange={handleChange} />
        <TextInput variant='outlined' label='Job title' name='jobTitle' value={user.jobTitle} onChange={handleChange} />
        <Grid >
          <Body2>{currentUser.email}</Body2>
          <Body2 className={styles.changeEmailText}>To change your email please contact <Link href='mailto:support@adludio.com'>support@adludio.com</Link></Body2>
        </Grid>
      </Grid>
    </ProfileEdit>
  );
};

export default UserProfileEdit;
