import React from 'react';
import UpdatePassword from './UpdatePassword';
import UserProfile from './UserProfile/index';
import { Box, Grid } from '@adludio/components';

export default function UserProfilePage() {
  return (
    <Grid
      container
      item
      direction='column'
      xs={12}
    >
      <Grid item>
        <Box pb='5%'>
          <UserProfile />
        </Box>
      </Grid>
      <Grid item>
        <UpdatePassword />
      </Grid>
    </Grid>
  );
}
