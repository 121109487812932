/* eslint-disable complexity */
import React from 'react';
import { navigate } from '@reach/router';
import {
  AdludioTheme,
  Card,
  Grid,
  Icon,
  makeStyles,
  CardContent,
  Body1,
  Caption,
  CardMedia,
  Tooltip,
} from '@adludio/components';
import { Campaign, useGetCampaignsQuery } from '../generated/graphql';
import { convertToDate } from '../helpers/date';
import BriefImage from '../static-assets/Brief.png';
import { StatusBadge } from './StatusBadge';
import { getBriefLink } from '../helpers/getBriefLink';

const styles = makeStyles((theme: AdludioTheme) => ({
  addNewCard: {
    height: '15.5rem',
    width: '16rem',
    [theme.breakpoints.only('xs')]: {
      height: '17rem',
    },
    [theme.breakpoints.only('sm')]: {
      height: '15rem',
    },
    boxSizing: 'border-box',
    '&:hover': {
      boxShadow: '0px 1px 5px rgba(0, 0, 2, 0.2)',
      border: `1px solid ${theme.palette.primary.main}`,
    },
    cursor: 'pointer',
  },
  plusIcon: {
    fontSize: '4.25rem',
    fontWeight: 'bold',
  },
  label: {
    color: theme.palette.grey[500],
  },
  thumbnail: {
    background: `url(${BriefImage})`,
    backgroundSize: 'cover',
    height: '9rem',
    [theme.breakpoints.only('xs')]: {
      height: '12rem',
    },
    [theme.breakpoints.only('sm')]: {
      height: '10rem',
    },
  },
  date: {
    color: theme.palette.grey[600],
  },
  test: {
    height: '100%',
  },
  title: {
    maxHeight: '1.3rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  cardContent: {
    height: '100%',
  },
  divContent: {
    height: '4.4rem',
  },
}));

export function BriefGrid() {
  const {
    addNewCard,
    plusIcon,
    label,
    thumbnail,
    test,
    date,
    title,
    cardContent,
    divContent,
  } = styles();
  const { data } = useGetCampaignsQuery();

  const handleClick = (campaign: Campaign) => {
    navigate(
      `campaigns/campaign/${campaign.id}/${getBriefLink(
        campaign.brief?.isSubmitted,
        campaign.creativeBrief?.isSubmitted ?? false,
        campaign.brief?.isManaged ?? false
      )}`
    ).catch(console.log);
  };

  return (
    <Grid item xs container spacing={7}>
      {/* Add New Campaign Card */}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        alignItems='center'
        justify='center'
      >
        <Card
          className={addNewCard}
          elevation={6}
          onClick={() => {
            navigate('/campaigns/add-new-campaign').catch(console.log);
          }}
        >
          <Grid container item xs className={test} alignItems='center'>
            <Grid
              container
              direction='column'
              alignItems='center'
              justify='center'
            >
              <Grid item xs>
                <Icon className={plusIcon} color={'disabled'} label=''>
                  add
                </Icon>
              </Grid>
              <Grid item xs>
                <Body1 className={label}>New Campaign</Body1>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {/* Briefs rendered here */}
      {data?.getCampaigns.map(
        (campaign) =>
          campaign.title === null && (
            <Grid
              item
              key={campaign.brief!.name}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={3}
            >
              <Card
                className={addNewCard}
                onClick={() => handleClick(campaign)}
              >
                <CardMedia className={thumbnail} />
                <CardContent className={cardContent}>
                  <Grid
                    container
                    direction='column'
                    wrap='nowrap'
                    xs={12}
                    className={divContent}
                  >
                    {/* /eslint-disable-next-line eqeqeq */}
                    {campaign.brief!.name != null &&
                    campaign.brief!.name.length > 30 ? (
                        <Tooltip
                          title={campaign.brief!.name!}
                          placement='top-start'
                        >
                          <Grid item xs>
                            <Body1 className={title}>
                              {campaign.brief!.name}
                            </Body1>
                          </Grid>
                        </Tooltip>
                      ) : (
                        <Grid item xs>
                          <Body1>{campaign.brief!.name ?? 'Untitled'}</Body1>
                        </Grid>
                      )}
                    {campaign.brief?.startDate && campaign.brief?.endDate && (
                      <Grid item container xs={12}>
                        <Caption className={date}>
                          {convertToDate(campaign.brief?.startDate)}-{' '}
                          {convertToDate(campaign.brief?.endDate)}
                        </Caption>
                      </Grid>
                    )}
                    <Grid
                      container
                      xs
                      justify='flex-end'
                      alignContent='flex-end'
                    >
                      <StatusBadge
                        status={getBriefLink(
                          campaign.brief?.isSubmitted,
                          campaign.creativeBrief?.isSubmitted ?? false,
                          campaign.brief?.isManaged ?? false
                        )}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )
      )}
    </Grid>
  );
}
