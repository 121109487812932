export const mockCurrencies = [
  { label: 'USD', value: 'USD', key: 'USD' },
  { label: 'EUR', value: 'EUR', key: 'EUR' },
  { label: 'GBP', value: 'GBP', key: 'GBP' },
  { label: 'AUD', value: 'AUD', key: 'AUD' },
  { label: 'HKD', value: 'HKD', key: 'HKD' },
  { label: 'IDR', value: 'IDR', key: 'IDR' },
  { label: 'MYR', value: 'MYR', key: 'MYR' },
  { label: 'R$', value: 'R$', key: 'R$' },
  { label: 'SGD', value: 'SGD', key: 'SGD' },
  { label: 'THB', value: 'THB', key: 'THB' },
  { label: 'TWD/NTD', value: 'TWD/NTD', key: 'TWD/NTD' },

];
