import {
  Body2,
  Dropzone,
  Grid,
  IconButton,
  useSnackbar,
  CircularProgress,
  Box,
} from '@adludio/components';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { joinWithoutEmpty } from './CreativeForm';
import DeleteIcon from '@material-ui/icons/Delete';
import { assetsUploadStyles as styles } from '../styles';
import {
  FileType,
  Operation,
  useGetMagicLinkLazyQuery,
  useSaveCreativeBriefMutation,
  useSaveMediaCampaignBriefMutation,
} from '../../../generated/graphql';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { acceptedFiletypes } from '../../../helpers/acceptedFiletypes';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CreativeFormValues, ManagedFormValues } from '../types';

interface Props {
  formikProps: FormikProps<CreativeFormValues> | FormikProps<ManagedFormValues>;
  campaignId: string;
  isManaged: boolean;
}

export const AssetUpload: React.FC<Props> = ({
  formikProps,
  campaignId,
  isManaged,
}) => {
  const {
    values: { assetsUrl },
    setFieldValue,
  } = formikProps;
  const { assetTitle, checkIcon } = styles();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState<File | null>(null);
  const [saveMedia] = useSaveMediaCampaignBriefMutation();
  const [saveProgrammatic] = useSaveCreativeBriefMutation();
  const [isUploading, setIsUploading] = useState(true);
  const [magicLinkReceived, setMagicLinkReceived] = useState(false);
  const [getMagicLink, { data, error: magicLinkError }] =
    useGetMagicLinkLazyQuery();

  const handleFiles = async () => {
    if (file) {
      setIsUploading(true);
      if (isManaged) {
        await saveMedia({
          variables: {
            id: campaignId,
            fieldsToSave: {
              ...formikProps.values,
            },
          },
        });
      } else {
        await saveProgrammatic({
          variables: {
            id: campaignId,
            fieldsToSave: {
              ...formikProps.values,
            },
          },
        });
      }
      getMagicLink({
        variables: {
          fileId: file.name,
          type: FileType.Assets,
          campaignId: campaignId,
          operation: Operation.Put,
        },
      });
    }
  };

  useEffect(() => {
    if (file)
      enqueueSnackbar('Error Occurred couldn\'t upload asset', {
        variant: 'error',
      });
    const newValue = assetsUrl.split(',');
    newValue.splice(newValue.length, 1);
    setFieldValue('assetsUrl', joinWithoutEmpty(newValue));
    setIsUploading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [magicLinkError]);

  useEffect(() => {
    if (magicLinkReceived && file) {
      const assetUrl = assetsUrl
        ? assetsUrl.concat(`, ${file!.name}`)
        : file!.name;
      setFieldValue('assetsUrl', assetUrl);
      fetch(data?.getMagicLink!, {
        method: 'PUT',
        body: file,
      })
        .then((response) => response.text())
        .then(async () => {
          setIsUploading(false);
          setMagicLinkReceived(false);
          if (isManaged) {
            saveMedia({
              variables: {
                id: campaignId,
                fieldsToSave: {
                  ...formikProps.values,
                },
              },
            });
          } else {
            saveProgrammatic({
              variables: {
                id: campaignId,
                fieldsToSave: {
                  ...formikProps.values,
                },
              },
            });
          }
        })
        .catch((e) => {
          setIsUploading(false);
          enqueueSnackbar('Error Occurred couldn\'t upload site list', {
            variant: 'error',
          });
          console.error(e);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [magicLinkReceived]);

  useEffect(() => {
    handleFiles();
  }, [file]);

  useEffect(() => {
    data && setMagicLinkReceived(true);
  }, [data]);

  const CardFileIcon = (fileName: string) => {
    const fileExt = fileName.split('.').pop();
    if (!fileExt) {
      return <></>;
    }
    return (
      <Box width='2rem'>
        <FileIcon extension={fileExt} {...defaultStyles[fileExt]} />
      </Box>
    );
  };

  const uploadStatus = (fileName: string): JSX.Element => {
    if (fileName) {
      return file?.name === fileName && isUploading ? (
        <>
          <Grid item justifyContent='space-between'>
            <Body2>Uploading</Body2>
          </Grid>
          <Grid item>
            <CircularProgress />
          </Grid>
        </>
      ) : (
        <CheckCircleIcon className={checkIcon} fontSize='large' />
      );
    }
    return <></>;
  };

  const assetUploadItem = (asset: string, index: number) => {
    return (
      <Grid
        container
        item
        xs={10}
        key={asset}
        direction='row'
        alignItems='center'
      >
        <Grid xs={1}>{CardFileIcon(asset)}</Grid>
        <Grid xs={7}>
          <Body2 className={assetTitle}>{asset}</Body2>
        </Grid>
        <Grid xs={3} item container direction='row'>
          {uploadStatus(asset)}
        </Grid>
        {assetsUrl.split(',').length > 1 && (
          <Grid xs={1}>
            <IconButton
              size='small'
              label='delete'
              onClick={() => {
                const newValue = assetsUrl.split(',');
                newValue.splice(index, 1);
                setFieldValue('assetsUrl', joinWithoutEmpty(newValue));
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <>
      <Grid container item xs={12}>
        <Dropzone
          numberOfFiles='single'
          file={file}
          setFile={setFile}
          accept={acceptedFiletypes}
        />
      </Grid>
      <Grid container xs={12} justifyContent='center'>
        {assetsUrl
          .split(',')
          .map((asset, index) => assetUploadItem(asset, index))}
      </Grid>
    </>
  );
};
