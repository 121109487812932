import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';

import getEnv, { Env } from '@adludio/react-sso/dist/getEnv';
import { getEnvPrefix } from './util/getEnvPrefix';

/*
 * Figuring out the correct path to our other services
 * by looking at current node env, basically:
 *
 * If env is:
 * production (live)     => sso.adludio.com
 * development (local)   => dev.sso.adludio.com
 * testing (alpha)       => testing.sso.adludio.com
 * staging (beta)        => staging.sso.adludio.com
 *
 */

const env = getEnv(process.env.REACT_APP_BUILD_ENV);
const envPrefix = getEnvPrefix();

export interface PortalConfig {
  env: Env,
  ssoBEURL: string,
  ssoFEURL: string,
  envPrefix: string,
}

export const initialConfig: PortalConfig = {
  env,
  ssoBEURL: `//${envPrefix}api.sso.adludio.com`,
  ssoFEURL: `//${envPrefix}sso.adludio.com`,
  envPrefix: envPrefix,
};

ReactGA.initialize('G-9KFC5FPW1N');

ReactDOM.render(<App config={initialConfig} />, document.getElementById('root'));

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
