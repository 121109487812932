import {
  AdludioTheme,
  Body2,
  Grid,
  Icon,
  makeStyles,
  Subtitle1,
  Box,
  Divider,
} from '@adludio/components';
import React from 'react';

interface NavElementProps {
  title: string;
  icon: string;
  closeTooltip: () => void;
}
const useStyles = makeStyles((theme: AdludioTheme) => ({
  arrow: {
    position: 'relative',
    top: '1rem',
    left: '84%',
  },
  divider: {
    width: '85%',
    background: 'white',
    marginBottom: '0.5rem',
    marginTop: '0.5rem',
  },
}));
export const DetailedTooltip = (props: NavElementProps) => {
  const { divider } = useStyles();
  const descriptions = {
    'Campaign Plan':
      'This section is currently disabled, you will have access to it as soon as your campaign manager uploads the campaign plan. ',
    Creative:
      'This section is currently disabled, you will have access to it as soon as your designer completes the ad units.',
    Reporting:
      'This section is currently disabled, you will have access to it as soon as your campaign starts.',
    Brief: 'The brief is not available',
    Files: 'Files are not available',
  };
  return (
    <Box width='100%' my='0.8rem'>
      <Grid container xs={12} justifyContent='center'>
        <Grid container direction='row' xs={10}>
          <Grid item xs={2}>
            <Icon label={props.title}>{props.icon}</Icon>
          </Grid>
          <Subtitle1>{props.title}</Subtitle1>
        </Grid>
        <Divider className={divider} />
        <Grid xs={10}>
          <Body2>{descriptions[props.title]}</Body2>
        </Grid>
      </Grid>
    </Box>
  );
};
