import React, { useState, Dispatch, SetStateAction } from 'react';
import {
  Body1,
  Body2,
  IconButton,
  Box,
  Grid,
  TextInput,
} from '@adludio/components';
import Edit from '@material-ui/icons/Edit';
import { useUpdateAdunitTitleMutation } from '../generated/graphql';
import { AdunitData } from './UploadAdunit';

interface AdunitInfoProps {
  setAdunitData: Dispatch<
    SetStateAction<AdunitData[]>
  >;
  adunitData: AdunitData;
}

export const AdunitInfo = ({ setAdunitData, adunitData }: AdunitInfoProps) => {
  const [editAdunit, setEditAdunit] = useState(false);
  const [adunitTitleInput, setAdunitTitleInput] = useState(adunitData.title);
  const [updateAdunitTitleMutation] = useUpdateAdunitTitleMutation({
    variables: {
      id: adunitData.id,
      title: adunitTitleInput!,
    },
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    return setAdunitTitleInput(e.target.value);
  };

  const keyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      setEditAdunit(false);
      updateAdunitTitleMutation()
        .then(() => {
          const updatedAdunitData = { ...adunitData };
          updatedAdunitData.title = adunitTitleInput!;
          setAdunitData((prevState) =>
            prevState.map((obj) =>
              updatedAdunitData.id === obj.id ? updatedAdunitData : obj
            )
          );
        })
        .catch(console.log);
    }
  };

  return (
    <>
      <Grid container direction='row' alignItems='center'>
        {editAdunit ? (
          <Grid xs={10}>
            <Box mb='1rem'>
              <TextInput
                defaultValue={adunitTitleInput}
                onKeyDown={keyPress}
                onChange={handleChange}
              />
            </Box>
          </Grid>
        ) : (
          <>
            <Body1 style={{ maxWidth: '17rem', overflowWrap: 'break-word' }}>
              {adunitData.title}
            </Body1>
            <Box ml='0.5rem' mb='0.5rem'>
              <IconButton label='edit' onClick={() => setEditAdunit(true)}>
                <Edit />
              </IconButton>
            </Box>
          </>
        )}
      </Grid>
      <Body2>{new Date(adunitData.createdAt).toLocaleDateString()}</Body2>
    </>
  );
};
