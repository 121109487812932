import {
  Body2,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Subtitle1,
  useSnackbar,
} from '@adludio/components';
import React, { useEffect, useState } from 'react';
import {
  FileType,
  Operation,
  useDeleteFileLazyQuery,
  useGetMagicLinkLazyQuery,
  useSaveCampaignBriefMutation,
  useSaveMediaCampaignBriefMutation,
} from '../../../generated/graphql';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import DeleteIcon from '@material-ui/icons/Delete';
import { SiteListStyles as styles } from '../styles';
import { FormikProps } from 'formik';
import { ManagedFormValues, ProgrammaticFormValues } from '../types';

interface Props {
  sitelistUrl?: string;
  formikProps: FormikProps<ProgrammaticFormValues> | FormikProps<ManagedFormValues>;
  campaignId: string;
  isManaged: boolean;
  error?: string;
}

export const SiteListUpload: React.FC<Props> = ({
  formikProps,
  sitelistUrl,
  isManaged,
  campaignId,
}) => {
  const { setFieldValue } = formikProps;
  const { purple, siteList, siteListGrid, siteListTitle } = styles();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteFile] = useDeleteFileLazyQuery();
  const [file, setFile] = useState<File>();
  const [saveMedia] = useSaveMediaCampaignBriefMutation();
  const [saveProgrammatic] = useSaveCampaignBriefMutation();
  const [isUploading, setIsUploading] = useState(false);
  const [magicLinkReceived, setMagicLinkReceived] = useState(false);
  const [getMagicLink, { data, error: magicLinkError }] =
    useGetMagicLinkLazyQuery();

  const handleFiles = async (file: File) => {
    if (isManaged && formikProps) {
      await saveMedia({
        variables: {
          id: campaignId,
          fieldsToSave: {
            ...formikProps.values,
          },
        },
      });
    }
    setFile(file);
    setIsUploading(true);
    getMagicLink({
      variables: {
        fileId: file.name,
        type: FileType.SiteList,
        campaignId: campaignId,
        operation: Operation.Put,
      },
    });
  };
  useEffect(() => {
    if (file)
      enqueueSnackbar('Error Occurred couldn\'t upload site list', {
        variant: 'error',
      });
    setIsUploading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [magicLinkError]);

  useEffect(() => {
    if (magicLinkReceived && file) {
      fetch(data?.getMagicLink!, {
        method: 'PUT',
        body: file,
      })
        .then((response) => response.text())
        .then(() => {
          const sitesUrl = sitelistUrl
            ? sitelistUrl.concat(`, ${file!.name}`)
            : file!.name;
          setFieldValue('sitelistUrl', sitesUrl);
          setIsUploading(false);
          setMagicLinkReceived(false);
          if (isManaged) {
            saveMedia({
              variables: {
                id: campaignId,
                fieldsToSave: {
                  ...formikProps.values,
                  sitelistUrl: sitesUrl,
                },
              },
            });
          } else {
            saveProgrammatic({
              variables: {
                id: campaignId,
                fieldsToSave: {
                  ...formikProps.values,
                  sitelistUrl: sitesUrl,
                },
              },
            });
          }
        })
        .catch((e) => {
          setIsUploading(false);
          enqueueSnackbar('Error Occurred couldn\'t upload site list', {
            variant: 'error',
          });
          console.error(e);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [magicLinkReceived]);

  useEffect(() => {
    data && setMagicLinkReceived(true);
  }, [data]);

  return (
    <Grid container item xs direction='column'>
      <Subtitle1 className={purple}>Site List</Subtitle1>
      <Grid>
        <Grid container item xs={12}>
          <Button
            variant='outlined'
            className={siteList}
            color='default'
            component='label'
            isLoading={isUploading}
          >
            Upload site list
            <Box ml='0.5rem' mt='0.3rem'>
              {!isUploading && <PresentToAllIcon />}
            </Box>
            <input
              type='file'
              hidden
              onChange={(e) => {
                handleFiles(e.target.files?.[0] as File);
              }}
            />
          </Button>
        </Grid>
        <Grid className={siteListGrid}>
          {sitelistUrl?.split(',').map((sitelist, idx) => (
            <Grid
              container
              xs={12}
              key={sitelist}
              direction='row'
              alignItems='center'
            >
              <Grid xs={5}>
                <Link>
                  <Body2 className={siteListTitle}>{sitelist}</Body2>
                </Link>
              </Grid>
              {sitelist && (
                <IconButton
                  size='small'
                  label='delete'
                  onClick={() => {
                    deleteFile({
                      variables: {
                        fileName: sitelist,
                        type: FileType.SiteList,
                        campaignId: campaignId!,
                      },
                    });
                    const newSiteList = sitelistUrl!
                      .split(',')
                      .filter((sitelistURL) => sitelistURL !== sitelist);
                    setFieldValue(
                      'sitelistUrl',
                      newSiteList.length === 1
                        ? newSiteList[0]
                        : (newSiteList.join() as string)
                    );
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
