import {
  AdludioTheme,
  Box,
  CircularProgress,
  Grid,
  makeStyles,
} from '@adludio/components';
import {
  CreativeBriefUpdatesDocument,
  CreativeBriefUpdatesSubscription,
  useGetCampaignQuery,
} from '../generated/graphql';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import { CreativeBriefOverview } from '../components/SingleBriefOverview/CreativeBrief/CreativeBriefOverview';
import { NewCampaignFormContext } from '../context/NewCampaignFormContext';

export interface ViewSingleBriefProps extends RouteComponentProps {
  campaignId?: string;
  setAddCreativeBrief: Dispatch<SetStateAction<boolean>>;
  setEditCreative: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: AdludioTheme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
  },
}));

export function ViewCreativeBrief(props: ViewSingleBriefProps): JSX.Element {
  const { root } = useStyles();
  const formContext = useContext(NewCampaignFormContext);
  const [requiresRender, setRequiresRender] = useState(true);
  const { data, loading, error, subscribeToMore } = useGetCampaignQuery({
    variables: { id: props.campaignId! },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!props.campaignId) navigate('/campaigns').catch(console.log);
  });

  useEffect(() => {
    // In case we DID call the getBrief query, set up the subscription to update the given response.
    data &&
      subscribeToMore!({
        document: CreativeBriefUpdatesDocument,
        variables: { id: data?.getCampaignById?.creativeBrief?.id },
        updateQuery: (prev, { subscriptionData }) => {
          const d = subscriptionData.data as CreativeBriefUpdatesSubscription;
          if (!d.creativeBriefUpdates) return prev;
          const updatedData = d.creativeBriefUpdates;
          setTimeout(() => setRequiresRender(true));
          return Object.assign({}, prev, {
            getCampaignBrief: updatedData,
          });
        },
      });
  }); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // If Campaign Brief doesn't exist OR already has a Creative Brief
    if (!loading) {
      if (!data?.getCampaignById?.creativeBrief || error) {
        console.log(error);
        props.setAddCreativeBrief(true);
      }
    }
  }, [data, error, loading, props, props.campaignId]);
  if (requiresRender && data?.getCampaignById?.creativeBrief) {
    formContext.dispatch!({
      type: 'useExisting',
      payload: data.getCampaignById.creativeBrief as object,
    });
    setRequiresRender(false);
  }

  return (
    <Grid container direction='column' className={root}>
      {loading ? (
        <Grid
          container
          item
          xs
          justifyContent='center'
          alignItems='center'
          style={{ height: '100vh' }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Box mt='2rem'>
          <Grid container alignItems='center' direction='column' spacing={4}>
            <Grid container item sm={12} md={8}>
              <CreativeBriefOverview
                campaignId={props.campaignId!}
                setEditCreative={props.setEditCreative}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Grid>
  );
}
