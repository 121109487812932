import { AdludioTheme, Box, Grid, IconButton, makeStyles } from '@adludio/components';
import React from 'react';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

interface NavElementProps {
  setExpandedNav: (value: boolean) => void;
  expandedNav: boolean
}
const useStyles = makeStyles((theme: AdludioTheme) => ({
  arrow: {
    position: 'relative',
    top: '1rem',
    left: '84%',
  },
  button: {
    backgroundColor: 'white',
    color: theme.palette.primary.dark,
    border: `1px solid ${theme.palette.grey[300]}`,
    fontSize: '1.45rem',
    padding: '0px'
  }
}));
export const ArrowNav = (props: NavElementProps) => {
  const { arrow, button } = useStyles();
  return (
    <Grid className={arrow} justifyContent={props.expandedNav ? 'flex-end' : 'center'}>
      <Box>
        <Box ml={props.expandedNav ? '1rem' : 0} >
          <IconButton className={button} onClick={() => props.setExpandedNav(!props.expandedNav)} label=''>
            {props.expandedNav ?
              <ChevronLeftIcon fontSize='inherit' />
              :
              <ChevronRightIcon fontSize='inherit' />
            }
          </IconButton>
        </Box>
      </Box>
    </Grid>
  );
};
