import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  makeStyles,
} from '@adludio/components';
import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';

import { AuthContext } from '@adludio/react-sso/dist';
import { CampaignBriefOverviewRead } from './CampaignBriefOverviewRead';
import { NewCampaignFormContext } from '../../../context/NewCampaignFormContext';
import { useGetCampaignQuery } from '../../../generated/graphql';

interface CampaignBriefOverviewrops {
  campaignId: string;
  setEditCampaignBrief: Dispatch<SetStateAction<boolean>>;
}
const useStyles = makeStyles(() => ({
  card: {
    borderRadius: '4px',
  },
}));

export function CampaignBriefOverview({
  campaignId,
  setEditCampaignBrief,
}: CampaignBriefOverviewrops): JSX.Element {
  const handleClick = () => setEditCampaignBrief(true);
  const { card } = useStyles();
  const { auth } = useContext(AuthContext);
  const isAdmin = auth.user?.role === 'admin' || auth.user?.role === 'cm';
  const { data, loading } = useGetCampaignQuery({
    variables: { id: campaignId },
    fetchPolicy: 'no-cache',
  });
  const formContext = useContext(NewCampaignFormContext);
  useEffect(() => {
    if (data?.getCampaignById?.creativeBrief) {
      formContext.dispatch!({
        type: 'useExisting',
        payload: data.getCampaignById.creativeBrief as object,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  return (
    <NewCampaignFormContext.Consumer>
      {(context) =>
        loading ? (
          <Grid
            container
            item
            xs
            justify='center'
            alignItems='center'
            style={{ height: '90vh' }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid container direction='column'>
              <Card elevation={5} className={card}>
                <Box p='2rem'>
                  <Grid direction='row-reverse' container>
                    <Grid container item xs={2} justify='flex-end'>
                      {!isAdmin && (
                        <Box mb='1rem'>
                          <Button variant='outlined' onClick={handleClick}>
                            Edit brief
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <CampaignBriefOverviewRead />
                </Box>
              </Card>
            </Grid>
          </>
        )
      }
    </NewCampaignFormContext.Consumer>
  );
}
