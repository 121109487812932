/* eslint-disable complexity */
// Disable as this is currently how reducer is supposed to be according to React official docs

import { Action } from '../models/action';
import { CampaignFormData } from '../context/NewCampaignFormContext';

export function newCampaignFormReducer(
  campaignFormData: CampaignFormData,
  action: Action
): CampaignFormData {
  switch (action.type) {
    case 'useExisting': {
      if ((action.payload as any).__typename === 'CreativeBrief') {
        let { __typename: _, ...creativeBrief } = action.payload as any;
        campaignFormData.creativeData = creativeBrief;
        return { ...campaignFormData };
      } else {
        let {
          __typename: _,
          creator: { id: creatorId },
          client: { id: clientId },
          creativeBrief,
          ...campaignData
        } = action.payload as any;
        if (creativeBrief) {
          const { __typename: ___, ...creativeData } = creativeBrief;
          creativeBrief = creativeData;
        }
        campaignFormData.campaignData = {
          ...campaignData,
          creatorId: creatorId ? parseInt(creatorId) : '',
          clientId: parseInt(clientId),
        };
        campaignFormData.creativeData = {
          ...creativeBrief,
          ...campaignFormData.creativeData,
        };
      }
      return { ...campaignFormData };
    }

    default:
      throw new Error();
  }
}
