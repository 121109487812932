import {
  AdludioTheme,
  Body1,
  Grid,
  Icon,
  makeStyles,
} from '@adludio/components';
import React, { MouseEvent } from 'react';

import AdludioLogo from './AdludioLogo';
import AlphaLogo from '../../shared/img/icons/alpha-icon-green.svg';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { ConfigProps } from '../../Pages';
import NavLink from '../NavLink';
import { ToolProps } from '../SideNav';
import Toolbar from '@material-ui/core/Toolbar';
import UserMenu from './UserMenu';
import { UserType } from '@adludio/react-sso/dist/types';
import pageTitle from './functions/pageTitle';

export interface NavProps {
  user?: UserType;
  tools: ToolProps[];
  supportItems: Omit<ToolProps, 'permission'>[];
  toggleSideNav?: (event: MouseEvent<HTMLDivElement>) => void;
  sideNavOpen: boolean;
  mobView?: boolean;
}

const useStyles = makeStyles((theme: AdludioTheme) => ({
  topNav: {
    flexGrow: 1,
    position: 'fixed',
    width: '100%',
    zIndex: 1300,
  },
  menuIcon: {
    color: theme.palette.primary.contrastText,
  },
  logoGridItem: {
    flexWrap: 'nowrap',
    height: '3.75rem',
    marginLeft: '0.875rem',
    width: '12rem',
    '@media only screen and (max-width: 425px)': {
      display: 'none',
    },
  },
  pageTitleGridItem: {
    '@media only screen and (max-width: 425px)': {
      display: 'flex',
      maxHeight: '3.75rem',
      justifyContent: 'center',
    },
    '@media only screen and (min-width: 425px)': {
      display: 'none',
    },
  },
  titleText: {
    '@media only screen and (max-width: 425px)': {
      paddingRight: '3.75rem',
    },
  },
  toolbarActionsGridContainer: {
    '@media only screen and (max-width: 425px)': {
      display: 'none',
    },
  },
  appBar: {
    maxHeight: '3.75rem',
    backgroundColor: theme.palette.primary.dark,
    boxShadow: 'none',
    color: theme.palette.grey[900],
    zIndex: 30,
  },
  loggedOutAppBar: {
    backgroundColor: theme.palette.primary.dark,
  },
  toolbar: {
    minHeight: 'unset',
    padding: '0px',
    maxHeight: '3.75rem',
    zIndex: 1201,
  },
  menuGridContainer: {
    maxHeight: '3.75rem',
  },
  menuGridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '3.75rem',
    width: '4em',
    cursor: 'pointer',
  },
  adludioLogo: {
    marginLeft: '10px',
    width: '4rem',
    height: '3.75rem',
  },
  icon: {
    margin: '0 10px',
    color: theme.palette.grey[50],
    cursor: 'pointer'
  },
  supportItemsGridContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  supportNavLink: {
    cursor: 'pointer',
    fontSize: '0.875rem',
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
  },
  profileGridItem: {
    cursor: 'pointer',
    paddingLeft: '1.5rem',
    marginRight: '15px',
  },
  landing: {
    display: 'none'
  }
}));

const TopNav = (props: NavProps & ConfigProps) => {
  const styles = useStyles();
  const auth = props.user ? true : false;
  return (
    <div className={auth ? styles.topNav : styles.landing}>
      <AppBar
        position='sticky'
        className={props.user ? styles.appBar : styles.loggedOutAppBar}
      >
        <Toolbar className={auth ? styles.toolbar : styles.landing} disableGutters variant='dense'>
          <Grid
            item
            container
            className='topNavGridContainer'
            justify='space-between'
            alignItems='center'
          >
            <Grid
              item
              container
              className={styles.menuGridContainer}
              wrap='nowrap'
              alignItems='center'
              xs
            >

              <Grid
                container
                item
                alignItems='center'
                className={styles.logoGridItem}
              >
                <NavLink className={styles.icon} to='/campaigns'>
                  <Icon label='Home'>home</Icon>
                </NavLink>
                <AdludioLogo />
                <img
                  src={AlphaLogo}
                  alt='Alpha'
                  className={styles.adludioLogo}
                />
              </Grid>
              <Grid item xs className={styles.pageTitleGridItem}>
                <Body1 className={styles.titleText}>
                  {pageTitle(window.location.pathname)}
                </Body1>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={styles.toolbarActionsGridContainer}
              justify='flex-end'
              alignItems='center'
              xs
            >
              <Box className={styles.icon}
                onClick={() => window.open('mailto:trish.heatley@adludio.com')}
              >
                <Icon label='Help' >help_outlined</Icon>
              </Box>
              {props.user ? (
                <Grid item className={styles.profileGridItem}>
                  <UserMenu
                    user={props.user}
                    ssoBEURL={props.config.ssoBEURL}
                    envPrefix={props.config.envPrefix}
                  />
                </Grid>
              ) : (
                <Box m={2}>
                  <Grid className={styles.supportItemsGridContainer}>
                    <Grid item className='supportGridItem'>
                      <NavLink
                        to={`${props.config.ssoFEURL}?payload=${window.btoa(
                          JSON.stringify({ url: window.location.href })
                        )}`}
                        className={styles.supportNavLink}
                      >
                        <Body1 className='loginNavLink'>Login</Body1>
                      </NavLink>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default TopNav;
