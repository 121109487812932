import {
  AdludioTheme,
  Box,
  Button,
  Grid,
  H6,
  RadioGroup,
  makeStyles,
} from '@adludio/components';
import React, { Dispatch, SetStateAction } from 'react';

import { Paper } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';

const styles = makeStyles((theme: AdludioTheme) => ({
  container: {
    display: 'flex',
    rowGap: '20px',
    marginTop: '5rem',
    backgroundColor: theme.palette.background.paper,
  },
  column: {
    display: 'flex',
    rowGap: '20px',
  },
  paper: {
    width: '100%',
    padding: '1rem',
  },
}));

interface TypeOfCampaignScreenProps extends RouteComponentProps {
  setIsMedia: Dispatch<SetStateAction<boolean>>;
  isMedia: boolean;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

export function TypeOfCampaignScreen({
  navigate,
  setIsMedia,
  isMedia,
  setActiveStep,
}: TypeOfCampaignScreenProps): JSX.Element {
  const { container, column, paper } = styles();

  return (
    <Grid container className={container} xs={8} spacing={4} justify='center'>
      <Paper className={paper} elevation={2}>
        <Grid className={column} direction='column' item xs={12}>
          <Grid item xs>
            <H6>Select your campaign type</H6>
          </Grid>
          <Box p='4%'>
            <RadioGroup
              ariaLabel='radio'
              label=''
              name='type of campaign'
              value={isMedia ? 'media' : 'programmatic'}
              values={[
                { label: 'Programmatic self serve', value: 'programmatic' },
                { label: 'Media included', value: 'media' },
              ]}
              onChange={(e) =>
                setIsMedia(e.target.value === 'programmatic' ? false : true)
              }
            />
          </Box>
          <Grid container item xs>
            <Box px='1rem'>
              <Button
                variant='outlined'
                onClick={() => {
                  navigate?.('/campaigns').catch(console.log);
                }}
              >
                Cancel
              </Button>
            </Box>
            <Box>
              <Button variant='contained' onClick={() => setActiveStep(1)}>
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
