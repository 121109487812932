import {
  AdludioTheme,
  Button,
  Dialog,
  Dropdown,
  Grid,
  TextInput,
  makeStyles,
  Box,
} from '@adludio/components';
import React, { useRef, useState } from 'react';

import axios from 'axios';
import { adunitTypes } from '../../helpers/adunitResolutions';
const styles = makeStyles((theme: AdludioTheme) => ({
  root: {
    margin: '3rem 0',
    width: '30rem',
  },
  resolutionRow: {
    paddingBottom: '2rem',
  },
}));

const AddAdunitModal = ({
  isOpen,
  onClose,
  acceptFn,
}: {
  isOpen: boolean;
  onClose: () => void;
  acceptFn: (
    gameKey: string,
    title: string,
    width: number,
    height: number
  ) => void;
}) => {
  const { root, resolutionRow } = styles();
  const [gameKey, setGameKey] = useState<string>('');
  const [title, setTitle] = useState<string>();
  const [height, setHeight] = useState<number>(480);
  const [width, setWidth] = useState<number>(320);
  const [adunitType, setAdunitType] = useState<string>('3');
  const formRef = React.useRef<HTMLFormElement | null>(null);
  const [disabled, setDisabled] = useState(true);
  const [gameKeyInvalid, setGameKeyInvalid] = useState(false);
  const cancelTokenSource = useRef(axios.CancelToken.source());

  const handleDisabled = () => {
    if (formRef.current && formRef.current!.checkValidity()) {
      setDisabled(!formRef.current!.reportValidity());
    }
    if (formRef.current && !formRef.current!.checkValidity()) {
      setDisabled(!formRef.current!.checkValidity());
    }
  };

  const handleSubmit = () => {
    setDisabled(true);
    cancelTokenSource.current.cancel();
    if (gameKey.length) {
      cancelTokenSource.current = axios.CancelToken.source();
      axios
        .get(`https://wat.adludio.com/games/${gameKey.trim()}/index.html`, {
          cancelToken: cancelTokenSource.current.token,
        })
        .then(() => {
          setGameKeyInvalid(false);
          setDisabled(!formRef.current!.checkValidity());
          if (formRef.current!.checkValidity()) {
            resetValues();
            acceptFn(gameKey!.trim(), title!, width, height);
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) return;
          setDisabled(true);
          setGameKeyInvalid(true);
        });
    }
  };

  const handleDropdownChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>,
    child: React.ReactNode
  ) => {
    const adunitData = adunitTypes.find(
      (adunitItem) => adunitItem.value === (e.target.value as string)
    );
    if (adunitData) {
      setHeight(adunitData.height);
      setWidth(adunitData.width);
    }
    setAdunitType(e.target.value as string);
  };

  const resetValues = () => {
    setGameKey('');
    setDisabled(true);
    setAdunitType('3');
    setTitle('');
    setWidth(0);
    setHeight(0);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <form ref={formRef}>
        <Grid
          container
          className={root}
          direction='column'
          justify='space-evenly'
          alignItems='center'
          spacing={3}
        >
          <Grid item xs={10}>
            <TextInput
              required
              variant='outlined'
              label='Title'
              value={title}
              // budget is NOT editing because a bug
              onChange={(e) => {
                setTitle(e.target.value);
                handleDisabled();
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <TextInput
              required
              variant='outlined'
              label='Game key'
              value={gameKey}
              error={gameKeyInvalid || (!gameKey.length && !disabled)}
              helperText={gameKeyInvalid ? 'Invalid Game Key' : ''}
              // budget is NOT editing because a bug
              onChange={(e) => {
                setGameKey(e.target.value);
                handleDisabled();
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <Box minWidth={'12.25rem'} pb='1.5rem'>
              <Dropdown
                fullWidth
                required
                menuItems={adunitTypes}
                variant='outlined'
                label='Adunit Type'
                value={adunitType}
                onChange={handleDropdownChange}
              />
            </Box>
          </Grid>
          {adunitType === '0' && (
            <Grid
              container
              direction='row'
              justifyContent='space-evenly'
              xs={6}
              className={resolutionRow}
            >
              <Grid item xs={5}>
                <TextInput
                  required
                  label='Width'
                  variant='outlined'
                  value={width}
                  type='number'
                  helperText=''
                  onChange={(e) => {
                    setWidth(parseInt(e.target.value));
                    handleDisabled();
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <TextInput
                  required
                  label='Height'
                  variant='outlined'
                  helperText=''
                  value={height}
                  type='number'
                  onChange={(e) => {
                    setHeight(parseInt(e.target.value));
                    handleDisabled();
                  }}
                />
              </Grid>
            </Grid>
          )}
          <Grid container direction='row' justifyContent='space-evenly' xs={6}>
            <Button variant='outlined' onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant='contained'
              disabled={disabled}
              onClick={() => handleSubmit()}
            >
              Add Ad Unit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default AddAdunitModal;
