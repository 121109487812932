/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable complexity */
import {
  AdludioTheme,
  Grid,
  TextInput,
  makeStyles,
  Subtitle1,
  Body2,
  Link,
  IconButton,
  Box,
} from '@adludio/components';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React from 'react';
import AddCircle from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import { FormikProps } from 'formik';

import { filter } from 'lodash';
import { validateLink } from '../../../helpers/validateLink';
import { AssetUpload } from './AssetUpload';
import { CreativeFormValues, ManagedFormValues } from '../types';

const styles = makeStyles((theme: AdludioTheme) => ({
  column: {
    display: 'flex',
    rowGap: '1.5rem',
  },
  helperText: {
    marginLeft: '0px',
  },
  purple: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  addLink: {
    color: theme.palette.grey[600],
  },
}));

export function joinWithoutEmpty(values: string[], delimiter = ',') {
  return filter(values).join(delimiter);
}

export const CreativeForm = ({
  formikProps,
  campaignId,
  isManaged,
}: {
  formikProps: FormikProps<ManagedFormValues> | FormikProps<CreativeFormValues>;
  campaignId: string;
  isManaged?: boolean;
}) => {
  const { values, handleChange, setFieldValue, errors } = formikProps;
  const { column, helperText, addLink, purple } = styles();

  const handleRadioGroupValue = (value?: boolean | null) => {
    if (value === null) return '';
    else if (value) {
      return 'yes';
    } else {
      return 'no';
    }
  };

  return (
    <Grid container justify='center'>
      <Grid container xs={8}>
        <Box width='100%' justifyContent='center' mb='1rem'>
          <Grid className={column} container item xs={12}>
            <Grid item xs={12}>
              <Box mt='1.5rem'>
                <Subtitle1 className={purple}>Your Brand</Subtitle1>
              </Box>
            </Grid>
            <Grid container item xs={12}>
              {/* WEBSITELINKS */}
              {values.websiteUrl.split(',').map((url, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box width='100%' key={index} my='0.5rem'>
                  <Grid container item xs justify='space-between'>
                    <Grid item xs={11}>
                      <TextInput
                        id='websiteUrl'
                        name='websiteUrl'
                        variant='outlined'
                        label='Brand website links'
                        value={url}
                        onChange={(e) => {
                          if (e.target.value[e.target.value.length - 1] === ',')
                            return;
                          const newValue = values.websiteUrl
                            ? values.websiteUrl.split(',')
                            : [''];
                          newValue[index] = e.target.value;
                          setFieldValue(
                            'websiteUrl',
                            joinWithoutEmpty(newValue)
                          );
                        }}
                        required
                        error={
                          (url !== '' && !validateLink(url)) ||
                          !!errors.websiteUrl
                        }
                        helperText={
                          errors.websiteUrl ??
                          (url !== '' &&
                            !validateLink(url) &&
                            'Introduce a valid URL')
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      {values.websiteUrl.split(',').length > 1 && (
                        <IconButton
                          onClick={() => {
                            const newValue = values.websiteUrl.split(',');
                            newValue.splice(index, 1);
                            setFieldValue(
                              'websiteUrl',
                              joinWithoutEmpty(newValue)
                            );
                          }}
                          label=''
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ))}
              {!values.websiteUrl.split(',').includes(' ') &&
                !values.websiteUrl.split(',').includes('') && (
                <Grid container item xs={12}>
                  <Body2>
                    <Link
                      className={addLink}
                      onClick={() =>
                        setFieldValue(
                          'websiteUrl',
                          joinWithoutEmpty(
                            values.websiteUrl.concat(', ').split(',')
                          )
                        )
                      }
                    >
                      <Grid container item xs alignItems='center'>
                        <Box mr='0.3rem'>
                          <AddCircle />
                        </Box>
                        <Body2>Add another URL</Body2>
                      </Grid>
                    </Link>
                  </Body2>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box mt='1.5rem'>
                <Subtitle1 className={purple}>Share your assets</Subtitle1>
              </Box>
            </Grid>

            <AssetUpload
              formikProps={formikProps}
              campaignId={campaignId}
              isManaged={!!isManaged}
            />
            <Grid item xs={12}>
              <Box mb='0.5rem'>
                <Subtitle1 className={purple}>
                  Make your product stand out
                </Subtitle1>
              </Box>
              <Grid xs={11}>
                <TextInput
                  variant='outlined'
                  name='focus'
                  value={values.focus}
                  onChange={handleChange}
                  helperText={
                    errors.focus ??
                    'What product do you want to showcase? (anything in particular, eg flavour/style )'
                  }
                  FormHelperTextProps={{
                    className: helperText,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <FormControl>
                <FormLabel id='promo-radio-buttons-group-label'>
                  Are you looking to promote an offer?
                </FormLabel>
                <RadioGroup
                  name='promoMessage'
                  value={
                    // eslint-disable-next-line no-undefined
                    values.promoMessage !== undefined
                      ? typeof values.promoMessage === 'string'
                        ? 'yes'
                        : 'no'
                      : ''
                  }
                  onChange={(event) => {
                    setFieldValue(
                      'promoMessage',
                      event.target.value === 'yes' ? '' : null
                    );
                  }}
                >
                  <FormControlLabel
                    value={'yes'}
                    control={<Radio required color='primary' />}
                    label={'Yes'}
                  />
                  <FormControlLabel
                    value={'no'}
                    control={<Radio required color='primary' />}
                    label={'No'}
                  />
                </RadioGroup>
              </FormControl>
              {typeof values.promoMessage === 'string' && (
                <Grid item xs={12}>
                  <Box mt='1rem'>
                    <TextInput
                      variant='outlined'
                      label='Promo message'
                      name='promoMessage'
                      defaultValue={values.promoMessage}
                      value={values.promoMessage}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id='seasonal-radio-buttons-group-label'>
                  Should the creative be seasonal?
                </FormLabel>
                <RadioGroup
                  name='isSeasonal'
                  value={handleRadioGroupValue(values.isSeasonal)}
                  onChange={(event) =>
                    setFieldValue('isSeasonal', event.target.value === 'yes')
                  }
                >
                  <FormControlLabel
                    value='yes'
                    control={<Radio required color='primary' />}
                    label='Yes'
                  />
                  <FormControlLabel
                    value='no'
                    control={<Radio required color='primary' />}
                    label='No'
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel>
                  Is the campaign for a new product or service that your
                  customers aren’t familiar with?
                </FormLabel>
                <RadioGroup
                  name='isNewProduct'
                  value={handleRadioGroupValue(values.isNewProduct)}
                  aria-labelledby='new-product-radio-buttons-group-label'
                  onChange={(event) =>
                    setFieldValue('isNewProduct', event.target.value === 'yes')
                  }
                >
                  <FormControlLabel
                    value='yes'
                    control={<Radio required color='primary' />}
                    label='Yes'
                  />
                  <FormControlLabel
                    value='no'
                    control={<Radio required color='primary' />}
                    label='No'
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          {!isManaged && (
            <Box pt='2rem'>
              <Grid
                container
                justify='flex-start'
                direction='row'
                spacing={1}
                xs={12}
                alignItems='center'
              >
                {/* <Grid item>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      // values.setEditCreative();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid> */}
                {/* <Grid item>
                  <Button
                    type='submit'
                    variant='contained'
                    onClick={(e) => handleSubmit(e as any)}
                  >
                    Submit
                  </Button>
                </Grid> */}
              </Grid>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
