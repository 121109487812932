import { CircularProgress, Grid } from '@adludio/components';

import { AuthContext } from '@adludio/react-sso/dist';
import { CampaignDocument } from '../../models/document';
import { ExternalUserScreen } from './externalUserScreen';
import { InternalUserScreen } from './InternalUserScreen';
import React from 'react';
import { useGetCampaignPlanDocumentsLazyQuery } from '../../generated/graphql';

interface CampaignPlanPage {
  campaignId: string;
}

export const CampaignPlanPage = (props: CampaignPlanPage): JSX.Element => {
  const { auth } = React.useContext(AuthContext);

  const [getCampaignPlanDocuments, { data: fileData, loading, refetch }] =
    useGetCampaignPlanDocumentsLazyQuery({
      variables: {
        campaignId: props.campaignId,
      },
    });

  React.useEffect(() => {
    getCampaignPlanDocuments();
  }, [getCampaignPlanDocuments]);

  const isAllowed = auth.user?.role === 'admin' || auth.user?.role === 'cm';

  return (
    <Grid container item xs={10}>
      {loading && <CircularProgress />}
      {isAllowed && fileData && (
        <InternalUserScreen
          files={fileData.getCampaignPlanDocuments as [CampaignDocument]}
          campaignId={props.campaignId}
          reFetch={refetch!}
        />
      )}
      {!isAllowed && fileData && (
        <ExternalUserScreen
          campaignId={props.campaignId}
          files={fileData.getCampaignPlanDocuments as [CampaignDocument]}
          reFetch={refetch!}
        />
      )}
    </Grid>
  );
};
