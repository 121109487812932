import {
  AdludioTheme,
  Box,
  CircularProgress,
  Grid,
  H5,
  makeStyles,
} from '@adludio/components';

import { BriefGrid } from '../components/BriefGrid';
import { Campaign } from '../models/campaign';
import { CampaignGrid } from '../components/CampaignGrid';
import { Dots } from '../components/Dots';
import DottedSVG from '../assets/Dotted-element-reverse.svg';
import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useGetCampaignsQuery } from '../generated/graphql';

interface Props extends RouteComponentProps {
  envPrefix: string;
}

const styles = makeStyles((theme: AdludioTheme) => ({
  container: {
    paddingTop: '1rem',
    paddingLeft: '1rem',
    width: '100%',
    minHeight: '100vh',
    backgroundColor: theme.palette.secondary.contrastText,
  },
  dotsDiv: {
    position: 'absolute',
    zIndex: 1,
  },
}));

export function CampaignsPage(props: Props): JSX.Element {
  const { data, loading } = useGetCampaignsQuery();
  const campaigns: Campaign[] = data
    ? data.getCampaigns.map((c) => ({
      id: c.id!,
      title: c.title!,
      slug: c.slug!,
      thumbnailUrl: c.thumbnailUrl!,
      startDate: c.brief?.startDate,
      endDate: c.brief?.endDate,
      status: c.status!,
      adunits: c.adunits!,
    }))
    : [];
  const { container, dotsDiv } = styles();

  return (
    <Grid
      container
      className={container}
      alignItems='center'
      justifyContent='space-between'
      direction='column'
    >
      {loading ? (
        <Grid container item xs justify='center' alignItems='center'>
          <Grid item xs={1}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Grid container direction='column' spacing={4} xs={11}>
          <Box className={dotsDiv}>
            <img src={DottedSVG} width='70%' />
          </Box>
          <Grid container item xs={12} style={{ zIndex: 2 }}>
            <Grid container spacing={4} direction='row'>
              <Grid container item xs={12} direction='row' spacing={2}>
                <Grid item xs={12}>
                  <H5>Briefs</H5>
                </Grid>
                <Grid item xs={12}>
                  <BriefGrid />
                </Grid>
              </Grid>
              <Grid container item xs direction='row' spacing={2}>
                <Grid item xs={12}>
                  <Box mt={6}>
                    <H5>Campaigns</H5>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <CampaignGrid campaigns={campaigns} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container justify='flex-end'>
        <Dots />
      </Grid>
    </Grid>
  );
}
