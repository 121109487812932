import { CampaignDocument, FileStatus } from '../../models/document';
import { useEffect, useState, useContext } from 'react';
import { FileType } from '../../generated/graphql';
import { AuthContext } from '@adludio/react-sso';

export function useCanApproveDocument(doc: CampaignDocument) {
  const { auth: { user } } = useContext(AuthContext);
  const [canApprove, setCanApprove] = useState<boolean>(false);

  useEffect(() => {
    if (doc.fileType === FileType.CampaignPlan && user?.role === 'client') {
      switch (doc.fileStatus) {
        case FileStatus.accepted:
        case FileStatus.archived:
          setCanApprove(false);
          break;
        case FileStatus.pending:
          setCanApprove(true);
          break;
      }
    }

  }, [doc, setCanApprove, user]);

  return canApprove;
}
