/* eslint-disable complexity */
import {
  Grid,
  TextInput,
  Subtitle1,
  Chip,
  Dropdown,
} from '@adludio/components';
import Autocomplete, {
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import { Box, FormControl } from '@material-ui/core';
import React, { useMemo } from 'react';
import { FormikProps } from 'formik';
import countryList from 'react-select-country-list';
import TextField from '@adludio/components/dist/Inputs/TextField';
import { mockDSPs } from '../../../mock-data/mockDSPs';
import { ManagedFormValues, ProgrammaticFormValues } from '../types';
import { targetingStyles as styles } from '../styles';
import { SiteListUpload } from './SiteListUpload';

export interface FormValues {
  audience: string;
  geographies: string;
  DSP: string;
  seatId: string;
  siteListUrl: string;
}

export const TargetingForm = ({
  campaignId,
  formikProps,
  isManaged,
}: {
  formikProps: FormikProps<ProgrammaticFormValues> | FormikProps<ManagedFormValues>;
  campaignId: string;
  isManaged?: boolean;
}) => {
  const { errors, values, handleChange, setFieldValue } = formikProps;
  const { column, purple } = styles();

  const countries = useMemo(() => countryList().getData(), []);
  const getValue = (c: string[]) => {
    const countriesList: string[] = [''];
    c.map((label, i) => {
      const countryItem = countries.find((country) => country.label === label);
      countryItem?.value && countriesList.push(countryItem.value);
    });
    return countriesList.filter((e) => e);
  };
  const getCountries = (c: string[]) => {
    const countriesList: string[] = [''];
    c.map((value, i) => {
      const countryItem = countries.find((country) => country.value === value);
      countryItem?.label && countriesList.push(countryItem.label);
    });
    return countriesList.filter((e) => e);
  };

  return (
    <>
      <Grid className={column} container justify='center' xs={12}>
        <Grid item xs={8}>
          <Subtitle1 className={purple}>Audience</Subtitle1>
        </Grid>
        <Grid container xs={8}>
          <TextInput
            id='audience'
            name='audience'
            variant='outlined'
            label='Audience'
            value={values.audience}
            onChange={handleChange}
            required
            rows={4}
            multiline
            error={!!errors.audience}
            helperText={errors.audience ?? 'Add target audience groups here.'}
          />
        </Grid>
        <Grid container xs={8} direction='column'>
          <Box mb='1rem' mt='1.5rem'>
            <Subtitle1 className={purple}>Serving Information</Subtitle1>
          </Box>
          <Autocomplete
            value={
              typeof values.geographies === 'string'
                ? getCountries(values.geographies!.split(','))
                : []
            }
            onChange={(event: React.ChangeEvent<{}>, newValue: string[]) => {
              const countriesValues = getValue(newValue);
              setFieldValue('geographies', countriesValues.join(',') as string);
            }}
            multiple
            id='geographies'
            options={countries.map((option) => option.label)}
            freeSolo
            renderTags={(
              value: string[],
              getTagProps: AutocompleteGetTagProps
            ) =>
              value.map((option: string, index: number) => (
                <Chip key={option} label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                name='geographies'
                required
                variant='outlined'
                label='Serving Locations'
                placeholder='Search'
                inputProps={{
                  ...params.inputProps,
                  required: values.geographies?.length === 0,
                }}
                error={!!errors.geographies}
                helperText={errors.geographies}
              />
            )}
          />
        </Grid>
        {!isManaged && (
          <>
            <Grid item xs={8}>
              <Dropdown
                required
                name='DSP'
                variant='outlined'
                label='DSP *'
                defaultValue={values.DSP}
                menuItems={mockDSPs}
                onChange={handleChange}
                error={!!errors.DSP}
                helperText={errors.DSP}
              />
            </Grid>
            <Grid item xs={8}>
              <TextInput
                required
                name='seatId'
                variant='outlined'
                label='Seat ID'
                defaultValue={values.seatId}
                onChange={handleChange}
                error={!!errors.seatId}
                helperText={errors.seatId}
              />
            </Grid>
          </>
        )}
        <Grid item xs={8}>
          <Box mb={2} mt='1.5rem'>
            <FormControl>
              <SiteListUpload
                sitelistUrl={values.sitelistUrl}
                error={errors.sitelistUrl}
                isManaged={!!isManaged}
                formikProps={formikProps}
                campaignId={campaignId}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
