/* eslint react/prop-types: 0 */
import * as React from 'react';

import {
  checkUsersBelogToMyCompany,
  sendInviteEmail,
} from '../../helpers/api/sso';

import { AuthContext } from '@adludio/react-sso';
import { EmailSent } from './EmailSent';
import { ErrorName } from './emailErrors';
import { InviteStep } from '../InviteTeam/InviteTeamWrapper';
import MyTeamDisplay from './InviteTeam';
import { NoUsersOnCompanyWarning } from './NoUsersOnCompanyWarning';

export interface Recipient {
  value: string;
  key: string;
  error: ErrorName | null;
}

interface MyTeamProps {
  campaignId: string;
  campaignStatus: string;
  campaignName: string;
  loading: boolean;
  invite: (...args: any[]) => Promise<any>;
  env: 'development' | 'staging' | 'production' | 'testing';
  inviteState: InviteStep;
  setInviteState: React.Dispatch<React.SetStateAction<InviteStep>>;
}

const MyTeam: React.FC<MyTeamProps> = ({
  env,
  campaignStatus,
  campaignId,
  campaignName,
  inviteState,
  setInviteState,
}) => {
  const {
    auth: { authToken },
  } = React.useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [emailsOutOfCompany, setEmailsOutOfCompany] = React.useState<string[]>(
    []
  );
  const [emailsForInvite, setEmailsForInvite] = React.useState<string[]>([]);
  const [timeout, setTimeoutFn] = React.useState<NodeJS.Timeout | null>(null);

  const sentInvites = async (data: { emails: string[] }) => {
    await sendInviteEmail(env, authToken, {
      campaignId,
      campaignName,
      campaignStatus,
      recipients: data.emails,
    })
      .then((res) => {
        setInviteState('EmailSent');
        return res.json();
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = async (data: { emails: string[] }) => {
    setIsLoading(true);
    checkUsersBelogToMyCompany(env, authToken, data)
      .then((res) => {
        if (res.data.emailsOutOfCompany.length === 0) {
          sentInvites(data).catch(console.error);
          return;
        }
        setEmailsForInvite(data.emails);
        setEmailsOutOfCompany(res.data.emailsOutOfCompany);
        setInviteState('NoCompanyEmailWarning');
      })
      .finally(() => setIsLoading(false));
  };

  const handleSubmitWithCompanyInvites = async (data: { emails: string[] }) => {
    setIsLoading(true);
    await sendInviteEmail(env, authToken, {
      campaignId,
      campaignName,
      campaignStatus,
      recipients: emailsForInvite,
      inviteToCompany: data.emails,
    })
      .then((res) => {
        setInviteState('EmailSent');
        return res.json();
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  };

  React.useEffect(() => {
    if (inviteState === 'EmailSent') {
      const t = setTimeout(function () {
        setInviteState('Form');
      }, 2000);
      setTimeoutFn(t);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteState]);

  switch (inviteState) {
    case 'Form':
      return (
        <MyTeamDisplay submitCallback={handleSubmit} loading={isLoading} />
      );
    case 'EmailSent':
      return <EmailSent />;
    case 'NoCompanyEmailWarning':
      return (
        <NoUsersOnCompanyWarning
          submitCallback={handleSubmitWithCompanyInvites}
          emails={emailsOutOfCompany}
        />
      );
    default:
      return (
        <MyTeamDisplay submitCallback={handleSubmit} loading={isLoading} />
      );
  }
};

export default MyTeam;
