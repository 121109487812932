import React from 'react';
import MainCard from './MainCard';
import Separator from './Separator';
import {
  Grid,
  Box,
} from '@adludio/components';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';


type GoogleCardProps = {
  isLarge?: boolean;
  isLogin?: boolean;
  onSuccess: (result: { email: string, accessToken: string }) => void;
  onFailure: () => void;
}

const Wrapper = ({ children, isLarge }: React.PropsWithChildren<{ isLarge?: boolean }>) => {
  if (!isLarge)
    return (
      <>
        <Box pt='5%' />
        <Separator />
        {children}
      </>
    );
  else
    return (
      <Grid container direction='column' justify='center' alignItems='center'>
        <MainCard secondary>{children}</MainCard>
      </Grid>
    );
};

export const GoogleCard = ({ isLarge, onSuccess, onFailure }: GoogleCardProps) => {

  return (
    <Wrapper isLarge={isLarge}>
      <Grid container direction='column' justify='center' alignItems='center'>
        <Grid
          xs={10}
          sm={8}
          md={10}
          container
          item
          direction='column'
          justify='center'
        >
          <Grid item>
            {isLarge && (
              <>
                <Box py='5%'>
                  <Separator />
                </Box>
              </>
            )}
          </Grid>
          <Grid item xs>
            <Grid container direction='column' justify='center' alignItems='center'>
              <div style={{ width: '57%', paddingTop: '6%' }}>
                <GoogleLogin
                  logo_alignment={'center'}
                  onSuccess={(credentialResponse: CredentialResponse) => {
                    if (credentialResponse.credential) {
                      const user: { email: string } = jwtDecode(credentialResponse.credential);
                      onSuccess({
                        email: user.email,
                        accessToken: credentialResponse.credential
                      });
                    } else {
                      onFailure();
                    }
                  }}
                  onError={onFailure}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
};


