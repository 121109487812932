import React from 'react';
import { Link } from '@reach/router';

const AdludioLogo = () => {
  return (
    <Link to='/campaigns'>
      <img
        className='adludioLogo'
        src={require('../../shared/img/adludio_logo.svg')}
        alt='adludio-logo'
      />
    </Link>
  );
};

export default AdludioLogo;
