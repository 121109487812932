import queryString, { ParsedQuery } from 'query-string';
import { getEnvPrefix } from './getEnvPrefix';

const envPrefix = getEnvPrefix();
const portalURL = `//${envPrefix}portal.adludio.com`;

type Payload = {
    url?: string;
    params: string;
  } | null

type URLQueryParams = {
   raw: string,
   encoded: ParsedQuery<string>
}

const getURLQueryParams = (): URLQueryParams => {
  const raw = window.location.search.replace(/\?/g, '');
  const encoded: ParsedQuery<string> = queryString.parse(raw);

  return { raw, encoded };
};

const decodePayload = (encodedParams: string) => {
  return window.atob(encodedParams);
};

const parsePayload = (decodedPayload: string) => {
  return JSON.parse(decodedPayload);
};

const getPayload = (encodedParams: ParsedQuery<string>) => {
  return encodedParams.payload ? parsePayload(decodePayload(encodedParams.payload as string)) : '';
};

const getRedirectUrl = (token: string, encodedPayload: string, decodedPayload: Payload ) => {
  const url = decodedPayload?.url ? decodedPayload.url : portalURL;
  const existingQuery = queryString.parseUrl(url).query;
  return queryString.stringifyUrl({ url, query: { ...existingQuery, token, payload: encodedPayload } });
};

const redirect = (token: string, encodedPayload: string, decodedPayload: Payload ) => {
  const url = getRedirectUrl(token, encodedPayload, decodedPayload);
  window.location.replace(url);
};

export const handleRedirect = (accessToken: string) => {
  const { encoded } = getURLQueryParams();

  const decodedPayload = getPayload(encoded);
  const encodedPayload = encoded.payload as string;
  redirect( accessToken, encodedPayload, decodedPayload);
};

