import React, { useState } from 'react';

import { Grid } from '@adludio/components';

import CompanyProfileView from './CompanyProfileView';
import CompanyProfileEdit from './CompanyProfileEdit';

export type Company = {
  id: number,
  name: string,
  industry: string
}


const CompanyProfile = () => {
  const [isEdit, setIsEdit] = useState(false);
  function toggleEditView(){
    setIsEdit(!isEdit);
  }

  return (
    <Grid item xs={12}>
      {isEdit ? (
        <CompanyProfileEdit switchView={toggleEditView} />
      ) : (
        <CompanyProfileView switchView={toggleEditView} />
      )}
    </Grid>
  );
};

export default CompanyProfile;
