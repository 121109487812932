import { Box, Button, Card, Grid } from '@adludio/components';
import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';

import { AuthContext } from '@adludio/react-sso/dist';
import { CreativeBriefOverviewRead } from './CreativeBriefOverviewRead';
import { NewCampaignFormContext } from '../../../context/NewCampaignFormContext';
import { RouteComponentProps } from '@reach/router';
import { useGetCampaignQuery } from '../../../generated/graphql';

export interface CreativeBriefOverviewProps extends RouteComponentProps {
  campaignId?: string;
  setEditCreative: Dispatch<SetStateAction<boolean>>;
}

export function CreativeBriefOverview(
  props: CreativeBriefOverviewProps
): JSX.Element {
  const handleClick = () => props.setEditCreative(true);
  const formContext = useContext(NewCampaignFormContext);
  const { data } = useGetCampaignQuery({
    variables: { id: props.campaignId },
    fetchPolicy: 'no-cache',
  });
  const { auth } = useContext(AuthContext);
  const isAdmin = auth.user?.role === 'admin' || auth.user?.role === 'cm';
  useEffect(() => {
    if (data?.getCampaignById?.creativeBrief) {
      formContext.dispatch!({
        type: 'useExisting',
        payload: data.getCampaignById.creativeBrief as object,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <NewCampaignFormContext.Consumer>
      {() => (
        <>
          <Grid container direction='column'>
            <Card elevation={2}>
              <Box p='2rem'>
                <Grid direction='row-reverse' container>
                  <Grid container item xs={2} justify='flex-end'>
                    {!isAdmin && (
                      <Button variant='outlined' onClick={handleClick}>
                        Edit brief
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid container justify='center'>
                  <CreativeBriefOverviewRead />
                </Grid>
              </Box>
            </Card>
          </Grid>
        </>
      )}
    </NewCampaignFormContext.Consumer>
  );
}
