/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint react/prop-types: 0 */
import * as React from 'react';
import { useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Subtitle1,
  Button,
  Box,
  Divider,
  Caption,
  Tooltip,
  Body2,
} from '@adludio/components';
import { CampaignDocument, FileStatus } from '../../models/document';
import { Chip } from '@material-ui/core';
import { FileIcon, defaultStyles } from 'react-file-icon';
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import AcceptPlanModal from '../Modals/AcceptPlan';
import { AdludioTheme } from '@adludio/components/dist/Theme/types';
import { FileType } from '../../generated/graphql';
import { useCanApproveDocument } from './useCanApproveDocument';
import { useDownload } from '../../helpers/useDownload';

interface DocumentCardProps {
  document: CampaignDocument;
  handleApprove: (documentId: string, newStatus: FileStatus) => void;
  hideStatus?: boolean;
}

const styles = makeStyles((theme: AdludioTheme) => ({
  root: {
    height: '16rem',
    width: '13rem',
    boxSizing: 'border-box',
    '&:hover': {
      boxShadow: '0px 1px 5px rgba(0, 0, 2, 0.2)',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  chip: {
    height: '1.5rem',
  },
  awaitingChip: {
    borderColor: theme.palette.warning.dark,
    color: theme.palette.warning.dark,
    height: '1.5rem',
  },
  approvedChip: {
    height: '1.5rem',
    color: theme.palette.success.dark,
    borderColor: theme.palette.success.dark,
  },
  filenameText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '10rem',
  },
}));

export const DocumentCard: React.FC<DocumentCardProps> = ({
  document,
  handleApprove,
  hideStatus,
}) => {
  const { root, chip, awaitingChip, approvedChip, filenameText } = styles();

  const isAwaiting: boolean = document.fileStatus === 'AWAITING';
  const isApproved: boolean = document.fileStatus === 'ACCEPTED';
  const canApprove = useCanApproveDocument(document);
  const [acceptPlanDialogOpen, setAcceptPlanDialogOpen] = useState(false);
  const { downloadDocument } = useDownload();
  const [hover, setHover] = useState<boolean>(false);

  /* some dates are not getting recognized as Dates but rather strings, probably TS things */
  const dateTime = (date: string) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString();
  };
  const CardFileIcon = (): JSX.Element => {
    const fileExt = document.fileName.split('.').pop();
    if (!fileExt) {
      return <></>;
    }
    return <FileIcon extension={fileExt} {...defaultStyles[fileExt]} />;
  };

  const StatusChip = (): JSX.Element => {
    let BadgeChip: JSX.Element = (
      <Chip
        label='Archived'
        variant='outlined'
        color='secondary'
        clickable={false}
        className={chip}
      />
    );
    if (isApproved) {
      BadgeChip = (
        <Chip
          label='Approved'
          variant='outlined'
          color='secondary'
          clickable={false}
          className={approvedChip}
        />
      );
    }
    if (isAwaiting) {
      BadgeChip = (
        <Chip
          label='Waiting approval'
          variant='outlined'
          color='secondary'
          clickable={false}
          className={awaitingChip}
        />
      );
    }
    return <Box py='1rem'>{BadgeChip}</Box>;
  };

  return (
    <>
      <Card className={root} elevation={6}>
        <CardContent>
          <Grid container direction='column' wrap='nowrap'>
            <Grid container justify='center'>
              {/* expression to remove the extension from the file name */}
              <Subtitle1>
                {document.fileType === FileType.CampaignPlan
                  ? 'Campaign Plan'
                  : 'Storyboard'}
              </Subtitle1>
            </Grid>
            <Box pt='0.5rem'>
              <Divider variant='fullWidth' />
            </Box>
            <Grid item container direction='column' alignItems='center'>
              <Grid item>
                {hideStatus ? (
                  <Box
                    py='1rem'
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    <Tooltip
                      title={document.fileName.replace(/\.[^/.]+$/, '')}
                      placement='bottom'
                      open={hover}
                    >
                      <div>
                        <Caption className={filenameText}>
                          {document.fileName.replace(/\.[^/.]+$/, '')}
                        </Caption>
                      </div>
                    </Tooltip>
                  </Box>
                ) : (
                  <StatusChip />
                )}
              </Grid>
              <Grid item xs={3}>
                <Box pl='0.5rem' pb='0.5rem'>
                  <CardFileIcon />
                </Box>
              </Grid>
              <Grid item>
                <Body2>{dateTime(document.uploadedAt.toString())}</Body2>
              </Grid>
            </Grid>
            <Box pt='0.5rem' pb='1rem'>
              <Divider variant='fullWidth' />
            </Box>
            <Grid item xs>
              <Grid container justify='space-around'>
                <Button
                  variant={
                    document.fileType !== FileType.CampaignPlan || !isAwaiting
                      ? 'contained'
                      : 'outlined'
                  }
                  size='small'
                  color='primary'
                  onClick={() => downloadDocument(document)}
                >
                  Download
                </Button>
                {isAwaiting && canApprove && (
                  <>
                    <Button
                      data-testId='approve-document'
                      variant='contained'
                      size='small'
                      onClick={() => setAcceptPlanDialogOpen(true)}
                    >
                      <Box px='0.6rem'>Accept</Box>
                    </Button>
                  </>
                )}
                <AcceptPlanModal
                  download={() => downloadDocument(document)}
                  documentId={document.id}
                  isOpen={acceptPlanDialogOpen}
                  campaignId={document.campaignId.id}
                  onClose={() => {
                    setAcceptPlanDialogOpen(false);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
