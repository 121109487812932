import React, { useContext, useState } from 'react';

import {
  Grid,
  TextInput,
  Dropdown,
  makeStyles,
  useSnackbar
} from '@adludio/components';

import { industryList } from './industryList';
import { AuthContext, SuccessAuthContextProps } from '@adludio/react-sso';
import { updateCompany } from '../../../API';
import { initialConfig } from '../../../index';
import ProfileEdit from '../ProfileEdit';

const useStyles = makeStyles({
  companyProfileDataContainer: {
    minHeight: '12rem'
  },
});

export interface CompanyProfileProps {
  switchView: () => void;
}

const CompanyProfileEdit = (props: CompanyProfileProps) => {
  const { auth: { user, authToken } } = useContext(AuthContext) as SuccessAuthContextProps;
  const [company, setCompany] = useState({ name: user.company.name, industry: user.company.industry });
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  function handleSubmit() {
    updateCompany(initialConfig.ssoBEURL, user.companyId, authToken, company)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          console.log(res.error);
          enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
        else {
          enqueueSnackbar('Change saved', { variant: 'success' });
          props.switchView();
        }
      })
      .catch(console.log);
  }
  function handleChange(e: any) {
    setCompany({ ...company, [e.target.name]: e.target.value });
  }
  return (
    <ProfileEdit handleSubmit={handleSubmit} switchView={props.switchView}>
      <Grid item container className={styles.companyProfileDataContainer} direction='column' justify='space-around' xs={12} md={6}>
        <TextInput variant='outlined' label='Company name' name='name' value={company.name} onChange={handleChange} />
        <Dropdown
          variant='outlined'
          label='Industry'
          name='industry'
          value={company.industry}
          menuItems={industryList}
          onChange={handleChange}
        />
      </Grid>
    </ProfileEdit>

  );
};

export default CompanyProfileEdit;
