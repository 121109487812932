import React, { useState } from 'react';

import { Grid } from '@adludio/components';

import UserProfileView from './UserProfileView';
import UserProfileEdit from './UserProfileEdit';

const UserProfile = () => {
  const [isEdit, setIsEdit] = useState(false);
  function toggleEditView(){
    setIsEdit(!isEdit);
  }

  return (
    <Grid xs={12}>
      {isEdit ? (
        <UserProfileEdit switchView={toggleEditView} />
      ) : (
        <UserProfileView switchView={toggleEditView} />
      )}
    </Grid>
  );
};

export default UserProfile;
