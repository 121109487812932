import React from 'react';
import { Grid } from '@adludio/components';
import CompanyProfile from './CompanyProfile/index';

export default function CompanyProfilePage(){
  return (
    <Grid
      container
      item
      direction='column'
      xs={12}
    >
      <CompanyProfile />
    </Grid>
  );
}
