import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Grid, Tabs, Tab, TabPanel, makeStyles, SnackbarProvider, Box } from '@adludio/components';
import UserProfilePage from './UserProfilePage';
import CompanyProfilePage from './CompanyProfilePage';

const useStyles = makeStyles(() => ({
  settingsPageContainer: {
    width: '35rem',
  },
  generalContainer: {
    width: '92vw',
    height: '90vh',
  },
  tabWrapper: {
    paddingBottom: '2rem',
  }
}));

/*
 * Usually props would look like this RouteComponentProps<{ index: number }>
 * but that makes the index prop potentially undefined, which it isn't.
 */
function SettingsPage({ index }: RouteComponentProps & { index: number }){
  const [activeTab, selectTab] = React.useState(index!);

  const { tabWrapper, settingsPageContainer, generalContainer } = useStyles();

  function navigateTabs(_event: React.ChangeEvent<{}>, selectedTab: number){
    let pathName;
    switch (selectedTab) {
      case 0: pathName = 'user-profile';
        break;
      case 1: pathName = 'company-profile';
    }
    window.history.replaceState(null, '', `/${pathName}`);
    selectTab(selectedTab);
  }

  return (
    <SnackbarProvider >
      <Box px='2.5rem' className={generalContainer}>
        <Grid container direction='column' className={settingsPageContainer}>
          <Grid item xs className={tabWrapper}>
            <Tabs value={activeTab} onChange={navigateTabs}>
              <Tab label='Your Profile' />
              <Tab label='Company Profile' />
            </Tabs>
          </Grid>
          <Grid>
            <TabPanel currentTab={activeTab} index={0}>
              <UserProfilePage />
            </TabPanel>
          </Grid>
          <Grid>
            <TabPanel currentTab={activeTab} index={1}>
              <CompanyProfilePage />
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
    </SnackbarProvider>

  );
}

export default SettingsPage;
