/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Caption,
  makeStyles,
  Body1,
  Tooltip,
} from '@adludio/components';
import { AdludioTheme } from '@adludio/components/dist/Theme/types';
import { Campaign } from '../models/campaign';
import placeholderThumbnail from '../static-assets/placeholder.svg';
import { convertToDate } from '../helpers/date';
import { Link } from '@reach/router';
import { StatusBadge } from './StatusBadge';
import { getCampaignStatus } from '../helpers/getCampaignStatus';
interface Props {
  campaign: Campaign;
}

const styles = makeStyles((theme: AdludioTheme) => ({
  root: {
    height: '15rem',
    width: '16rem',
    [theme.breakpoints.only('xs')]: {
      height: '17rem',
      width: '18rem',
    },
    [theme.breakpoints.only('sm')]: {
      height: '15rem',
      width: '18rem',
    },
    boxSizing: 'border-box',
    '&:hover': {
      boxShadow: '0px 1px 5px rgba(0, 0, 2, 0.2)',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  thumbnail: {
    height: '9rem',
    background: `url(${placeholderThumbnail})`,
    backgroundSize: 'cover',
    ObjectFit: 'cover',
    [theme.breakpoints.only('xs')]: {
      height: '12rem',
    },
    [theme.breakpoints.only('sm')]: {
      height: '10rem',
    },
  },
  date: {
    color: theme.palette.grey[600],
  },
  contentCard: {
    height: '4rem',
  },
  title: {
    maxHeight: '1.3rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export function CampaignCard(props: Props): JSX.Element {
  const { root, thumbnail, date, contentCard, title } = styles();
  const today = new Date();
  const endDate = new Date(props.campaign.endDate);
  const startDate = new Date(props.campaign.startDate);

  const getDesiredRoute = () => {
    return props.campaign.status === 'PRODUCTION' && today < startDate
      ? 'creativeProduction'
      : 'reporting';
  };

  return (
    <>
      <Card className={root} elevation={6}>
        <Link
          to={`/campaigns/campaign/${props.campaign.id}/${getDesiredRoute()}`}
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          <CardMedia
            className={thumbnail}
            image={props.campaign.thumbnailUrl}
          />
          <CardContent className={contentCard}>
            <Grid
              container
              direction='column'
              wrap='nowrap'
              xs={12}
              className={contentCard}
            >
              {props.campaign.title.length > 30 ? (
                <Tooltip title={props.campaign.title} placement='top-start'>
                  <Grid item xs>
                    <Body1 className={title}>{props.campaign.title}</Body1>
                  </Grid>
                </Tooltip>
              ) : (
                <Grid item xs>
                  <Body1>{props.campaign.title}</Body1>
                </Grid>
              )}
              <Grid item container xs={12}>
                {props.campaign.startDate && props.campaign.endDate && (
                  <Caption className={date}>
                    {convertToDate(props.campaign.startDate)}-{' '}
                    {convertToDate(props.campaign.endDate)}
                  </Caption>
                )}
              </Grid>
              <Grid item xs>
                <Grid container xs justify='flex-end' alignContent='flex-end'>
                  <StatusBadge
                    status={getCampaignStatus(
                      startDate,
                      endDate,
                      props.campaign.status,
                      props.campaign.adunits.length
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Link>
      </Card>
    </>
  );
}
