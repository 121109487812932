/* eslint-disable complexity */
import React from 'react';

import {
  AdludioTheme,
  Body1,
  Box,
  Grid,
  H4,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@adludio/components';

import { RouteComponentProps } from '@reach/router';

import backgroundSplodges from '../../shared/img/Edited-transparent.png';
import Login from '../../Components/Login';
import { AdludioLogo } from '../../Components/AdludioLogo';
import DotsLanding from '../../shared/img/DotsLanding.svg';

const useStyles = makeStyles((theme: AdludioTheme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${backgroundSplodges})`,
      backgroundPosition: 'bottom left',
      backgroundSize: '60%',
      backgroundRepeat: 'no-repeat',
      minHeight: 'calc(100vh - 4em)'
    }
  },
  header: {
    color: theme.palette.primary.main,
  },
  body: {
    color: theme.palette.secondary.dark
  },
  dots: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: -1
  }
}));

const Landing = (_: RouteComponentProps) => {
  const { container, header, body, dots } = useStyles();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  // This is here to get around the max complexity rule.
  const textAlignment = isDesktop ? 'left' : 'center';

  return (
    <Box px='9.5%' pt={isDesktop ? '0rem' : '2rem'} className={container}>
      <img src={DotsLanding} alt='dots' className={dots} />
      <Grid container xs={12} justify={isDesktop ? 'flex-start' : 'center'}>
        <Box pb='2rem' >
          <AdludioLogo isLogin />
        </Box>
      </Grid>
      <Grid container direction={isDesktop ? 'row' : 'column-reverse'} justify='center' alignItems={isDesktop ? 'flex-start' : 'center'} spacing={6}>
        <Grid item container md={8} xl={4} spacing={2} justify={'flex-start'} >
          <Grid container xs={12} justify={isDesktop ? 'flex-start' : 'center'} >
            <Grid container sm={10} md={8}>
              <Box pb={'2.5rem'} pt='0.5rem'>
                <H4 align={textAlignment} className={header}>
                  Adludio helps your brand launch high engagement creative
                </H4>
              </Box>
            </Grid>
            <Grid container xs={8}>
              <Body1 align={textAlignment} className={body}>
                The Adludio team are experts at delivering mobile first high
                engagement campaigns. Tell us what you want to achieve and we’ll
                take care of the creative build, the serving, and optimise as we go
                to achieve the best results possible.
              </Body1>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={4} justify={isDesktop ? 'flex-end' : 'center'}>
          <Login />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Landing;
