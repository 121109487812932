/* eslint-disable complexity */
import {
  AdludioTheme,
  Body1,
  Body2,
  Subtitle1,
  Grid,
  H6,
  makeStyles,
  Link,
} from '@adludio/components';

import { NewCampaignFormContext } from '../../../context/NewCampaignFormContext';
import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

const styles = makeStyles((theme: AdludioTheme) => ({
  link: {
    overflowWrap: 'anywhere',
  },
  purple: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
}));

export function CreativeBriefOverviewRead(
  props: RouteComponentProps
): JSX.Element {
  const { link, purple } = styles();
  return (
    <NewCampaignFormContext.Consumer>
      {(context) => (
        <Grid container xs={10} direction='row' spacing={4}>
          <Grid container item xs={10}>
            <H6 color='secondary'>Creative Information</H6>
          </Grid>
          <Grid item xs={10}>
            <Subtitle1 className={purple}>Creative URLs</Subtitle1>
          </Grid>
          <Grid item xs={10}>
            <Body2 color='textSecondary'>Brand website links</Body2>
            {context.state.creativeData?.websiteUrl?.split(',').map((url) => (
              <Link
                onClick={() => window.open(`//${url}`)}
                target='_blank'
                key={url}
              >
                <Body1 className={link} key={url}>
                  {url}
                </Body1>
              </Link>
            )) || <Body1>Not specified</Body1>}
          </Grid>
          <Grid item xs={10}>
            <Body2 color='textSecondary'>Assets url</Body2>
            {context.state.creativeData?.assetsUrl?.split(',').map((asset) => (
              <Link onClick={() => navigate('files')} key={asset}>
                <Body1 className={link} key={asset}>
                  {asset}
                </Body1>
              </Link>
            )) || <Body1>Not specified</Body1>}
          </Grid>
          <Grid item xs={10}>
            <Subtitle1 className={purple}>Creative options</Subtitle1>
          </Grid>
          <Grid item xs={10}>
            <Body2 color='textSecondary'>Main focus</Body2>
            <Body1>
              {context.state.creativeData?.focus || 'Not specified'}
            </Body1>
          </Grid>
          {context.state.creativeData?.promoMessage ? (
            <Grid item xs={10}>
              <Body2 color='textSecondary'>Promo message</Body2>
              <Body1>{context.state.creativeData.promoMessage}</Body1>
            </Grid>
          ) : null}
          <Grid item xs={10}>
            <Body2 color='textSecondary'>
              Should the creative be seasonal?
            </Body2>
            <Grid container item xs direction='row' alignItems='center'>
              <Body1>
                {context.state.creativeData?.isSeasonal ? 'Yes' : 'No'}
              </Body1>
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Body2 color='textSecondary'>
              Is the campaign for a new product or service that your customers
              aren’t familiar with?
            </Body2>
            <Grid container item xs direction='row' alignItems='center'>
              <Body1>
                {context.state.creativeData?.isNewProduct ? 'Yes' : 'No'}
              </Body1>
            </Grid>
          </Grid>
        </Grid>
      )}
    </NewCampaignFormContext.Consumer>
  );
}
