import React from 'react';
import { makeStyles, Box, AdludioTheme } from '@adludio/components';
import SvgDots from '../assets/Dotted-element-1.svg';

const useStyles = makeStyles((theme: AdludioTheme) => ({
  mainDiv: {
    position: 'relative',
    zIndex: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export const Dots = () => {
  const { mainDiv } = useStyles();
  return (
    <Box className={mainDiv}>
      <img src={SvgDots} />
    </Box>
  );
};
