import { Box, Grid } from '@adludio/components';
import {
  CampaignBriefUpdatesDocument,
  CampaignBriefUpdatesSubscription,
  useGetCampaignBriefQuery,
} from '../generated/graphql';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { CampaignBriefOverview } from '../components/SingleBriefOverview/CampaignBrief/CampaignBriefOverview';
import { navigate } from '@reach/router';
import { newCampaignFormContextDefaultValue } from '../context/NewCampaignFormContext';
import { newCampaignFormReducer } from '../reducers/newCampaignFormReducer';

const ViewCampaignBrief = ({
  campaignId,
  setEditCampaignBrief,
  editCampaignBrief,
}: {
  campaignId?: string;
  setEditCampaignBrief: Dispatch<SetStateAction<boolean>>;
  editCampaignBrief: boolean;
}) => {
  const [, dispatch] = useReducer(
    newCampaignFormReducer,
    newCampaignFormContextDefaultValue.state
  );
  const [requiresRender, setRequiresRender] = useState(true);
  const { loading, data, subscribeToMore } = useGetCampaignBriefQuery({
    variables: { campaignId: campaignId! },
  });
  useEffect(() => {
    if (!campaignId) navigate('/campaigns').catch(console.log);
  });
  useEffect(() => {
    // In case we DID call the getBrief query, set up the subscription to update the given response.
    subscribeToMore!({
      document: CampaignBriefUpdatesDocument,
      variables: { id: data?.getCampaignBrief?.id },
      updateQuery: (prev, { subscriptionData }) => {
        const d = subscriptionData.data as CampaignBriefUpdatesSubscription;
        if (!d.campaignBriefUpdates) return prev;
        const updatedData = d.campaignBriefUpdates;
        setTimeout(() => setRequiresRender(true));
        return Object.assign({}, prev, {
          getCampaignBrief: updatedData,
        });
      },
    });
  });

  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (data) {
      dispatch({
        type: 'useExisting',
        payload: data.getCampaignBrief as object,
      });
    }
  }, [data, dispatch]);

  if (requiresRender && data) {
    dispatch({ type: 'useExisting', payload: data.getCampaignBrief as object });
    setRequiresRender(false);
  }

  if (loading) {
    return <></>;
  }

  return (
    <Grid container direction='column'>
      <Box mt='2rem'>
        <Grid container alignItems='center' direction='column' spacing={4}>
          <Grid container item sm={12} md={8} lg={8}>
            <CampaignBriefOverview
              campaignId={campaignId!}
              setEditCampaignBrief={setEditCampaignBrief}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ViewCampaignBrief;
