/* eslint-disable complexity */
import { AdludioTheme, Grid, makeStyles, TextInput } from '@adludio/components';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Box,
} from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';

import { FormikProps } from 'formik';

import { mockPlacementSizes } from '../../../mock-data/mockPlacementSizes';
import Checkbox from '@adludio/components/dist/Inputs/Checkbox/Checkbox';
import { ManagedFormValues } from '../types';

const styles = makeStyles((theme: AdludioTheme) => ({
  column: {
    display: 'flex',
    rowGap: '20px',
  },
  purple: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  helperText: {
    marginLeft: '0px',
  },
  otherPlacement: {
    marginTop: '20px',
  },
}));

export const MediaTargetingForm = ({
  formikProps,
  campaignId,
}: {
  formikProps: FormikProps<ManagedFormValues>;
  campaignId: string;
}) => {
  const { values, setFieldValue, errors } = formikProps;
  const { column, otherPlacement, helperText } = styles();
  const [placementSizes, setPlacementSizes] = useState<string[]>([]);
  const [otherPlacementSizes, setOtherPlacementSizes] = useState<string>();
  const [otherPlacementSizesActive, setOtherPlacementSizesActive] =
    useState<boolean>();

  const handleRadioGroupValue = (value?: boolean | null) => {
    // eslint-disable-next-line no-undefined
    if (value === null) return '';
    else if (value) {
      return 'yes';
    } else {
      return 'no';
    }
  };

  const getIsChecked = (placements: string, placement: string) => {
    const placementValues = placements.split(',');
    if (placementValues.includes(placement) && placement !== 'Other')
      return true;
    if (placement === 'Other') {
      const other = placementValues.filter(
        (x) =>
          !mockPlacementSizes.some((mockPlacement) => mockPlacement.value === x)
      );
      if (other[0] || otherPlacementSizesActive) {
        return true;
      }
    }
    return false;
  };

  const handleOther = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && !otherPlacementSizesActive) {
      setOtherPlacementSizesActive(true);
      setOtherPlacementSizes('');
      setFieldValue(
        'placementSize',
        placementSizes.length ? placementSizes.join(',') : ''
      );
    } else if (otherPlacementSizesActive) {
      const changedPlacements = placementSizes.length
        ? placementSizes.join(',')
        : null;
      setOtherPlacementSizesActive(false);
      setOtherPlacementSizes('');
      setFieldValue('placementSize', changedPlacements);
    }
  };

  const handleChangePlacement = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (placementSizes.indexOf(event.target.value) === -1) {
        const newPlacements = [...placementSizes, event.target.value];
        setPlacementSizes(newPlacements);
        return newPlacements;
      }
    } else {
      const index = placementSizes.indexOf(event.target.value);
      const newPlacements = [...placementSizes];
      if (index !== -1 && placementSizes.length > 0) {
        newPlacements.splice(index, 1);
        setPlacementSizes(newPlacements);
        if (!newPlacements.length) return;
        return newPlacements;
      }
    }
    return;
  };

  const handlePlacementsChange = (event: ChangeEvent<HTMLInputElement>) => {
    try {
      if (event.target.value === 'Other') {
        handleOther(event);
        return;
      }
      const newPlacements = handleChangePlacement(event);
      const otherPlacements = otherPlacementSizesActive
        ? otherPlacementSizes || ''
        : '';

      if (newPlacements) {
        const changedPlacements = newPlacements.length
          ? newPlacements.join(',').concat(',').concat(otherPlacements)
          : otherPlacements;
        setFieldValue('placementSize', changedPlacements);
      } else if (!event.target.checked) {
        setFieldValue(
          'placementSize',
          otherPlacementSizesActive && otherPlacements ? otherPlacements : null
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid className={column} container justify='center' xs={12}>
      <Grid item xs={8}>
        <Box pb='1.5rem'>
          <FormControl error={!!errors.bio}>
            <FormLabel id='new-product-radio-buttons-group-label'>
              Is BIO Required?
            </FormLabel>
            <RadioGroup
              name='bio'
              value={handleRadioGroupValue(values.bio)}
              aria-labelledby='bio-required-radio-buttons-group-label'
              onChange={(e) => setFieldValue('bio', e.target.value === 'yes')}
            >
              <FormControlLabel
                value='yes'
                control={<Radio required color='primary' />}
                label='Yes'
              />
              <FormControlLabel
                value='no'
                control={<Radio required color='primary' />}
                label='No'
              />
            </RadioGroup>
            {errors.bio && <FormHelperText>{errors.bio}</FormHelperText>}
          </FormControl>
        </Box>
        <Grid xs={8}>
          <Box pb='2rem'>
            <FormControl
              required
              variant='outlined'
              error={!!errors.placementSize}
            >
              <FormLabel>
                {errors.placementSize ?? 'Placement and size'}
              </FormLabel>
              <FormHelperText className={helperText}>
                Choose all that apply
              </FormHelperText>
              <FormGroup aria-label='placement'>
                {mockPlacementSizes.map((c) => (
                  <FormControlLabel
                    key={c.key}
                    className={helperText}
                    label={c.label}
                    control={
                      <Checkbox
                        size='medium'
                        checked={getIsChecked(
                          values.placementSize || '',
                          c.value
                        )}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          handlePlacementsChange(event);
                        }}
                        value={c.value}
                      />
                    }
                  />
                ))}
                {otherPlacementSizesActive && (
                  <Grid item className={otherPlacement} xs={12}>
                    <TextInput
                      required={otherPlacementSizesActive}
                      variant='outlined'
                      label='Enter details of desired placement'
                      InputLabelProps={{ shrink: true }}
                      value={otherPlacementSizes}
                      onChange={(event) => {
                        const placementChange = placementSizes
                          .join(',')
                          .concat(',')
                          .concat(event.target.value || '');
                        setOtherPlacementSizes(event.target.value);
                        setFieldValue(
                          'placementSize',
                          placementSizes.length
                            ? placementChange
                            : event.target.value
                        );
                      }}
                    />
                  </Grid>
                )}
              </FormGroup>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
