/* eslint-disable no-undefined */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
  GetCampaignBriefQuery,
  GetCampaignQuery,
} from '../../generated/graphql';
import {
  ProgrammaticFormValues,
  ManagedFormValues,
  CreativeFormValues,
} from './types';

// Programmatic

export const PROGRAMMATIC_FORM_VALUES: ProgrammaticFormValues = {
  brandName: '',
  name: '',
  currency: 'USD',
  budget: null,
  description: '',
  industry: '',
  startDate: null,
  endDate: null,
  objective: '',
  KPI: '',
  audience: '',
  isManaged: false,
  geographies: '',
  DSP: '',
  seatId: '',
  sitelistUrl: '',
  isSubmitted: false,
};

// eslint-disable-next-line complexity
export const validateProgrammatic = (
  values: ProgrammaticFormValues,
  step: number,
  handleStepChange: (n: number) => void,
  save: (...args: any) => Promise<any>,
  campaignId: string,
  enqueueSnackbar: (message: string, options: any) => void
) => {
  const errors = {};
  // Multi-step validation
  if (step === 1) {
    if (!values.brandName) {
      errors['brandName'] = 'Brand name is required.';
    }
    if (!values.name) {
      errors['name'] = 'Campaign name is required.';
    }
    if (!values.budget) {
      errors['budget'] = 'Budget is required.';
    }
    if (!values.description) {
      errors['description'] = 'Description is required.';
    }
    if (!values.industry) {
      errors['industry'] = 'Industry is required.';
    }
    if (!values.startDate) {
      errors['startDate'] = 'Start date is required.';
    } else if (values.startDate < new Date()) {
      errors['startDate'] = 'Must be a future date.';
    }
    if (!values.endDate) {
      errors['endDate'] = 'End date is required.';
    } else if (values.startDate && values.endDate < values.startDate) {
      errors['endDate'] = 'End date must be after the Start date.';
    }
    if (!values.objective) {
      errors['objective'] = 'Objective is required.';
    }
    if (!values.KPI) {
      errors['KPI'] = 'KPI is required.';
    }

    if (Object.keys(errors).length === 0) {
      save({
        variables: {
          id: campaignId,
          fieldsToSave: {
            ...values,
            name: values.name ? values.name : null,
          },
        },
      })
        .then(() => {
          enqueueSnackbar('Successfully saved draft.', { variant: 'success' });
        })
        .catch(
          () => (errors['name'] = 'A campaign with that name already exists.')
        );

      if (Object.keys(errors).length === 0) {
        handleStepChange(step + 1);
        return;
      } else {
        return errors;
      }
    }
    return errors;
  } else {
    if (!values.audience) {
      errors['audience'] = 'Audience is required.';
    }
    if (!values.geographies) {
      errors['geographies'] = 'Serving Locations is required.';
    }
    if (!values.DSP) {
      errors['DSP'] = 'DSP is required.';
    }
    if (!values.seatId) {
      errors['seatId'] = 'SeatID is required.';
    }
    return Object.keys(errors).length > 0 ? errors : undefined;
  }
};

export const parseQueryToFormValues = (data?: GetCampaignBriefQuery) => {
  if (data) {
    const { id, client, creator, __typename, ...rest } = data.getCampaignBrief!;
    return rest as ProgrammaticFormValues;
  } else {
    return;
  }
};

// Managed

export const MANAGED_FORM_VALUES: ManagedFormValues = {
  brandName: '',
  name: undefined,
  currency: 'USD',
  budget: null,
  description: '',
  industry: '',
  startDate: null,
  endDate: null,
  objective: '',
  KPI: '',
  audience: '',
  geographies: '',
  DSP: '',
  sitelistUrl: '',
  websiteUrl: '',
  assetsUrl: '',
  focus: '',
  promoMessage: undefined,
  isSeasonal: null,
  isNewProduct: null,
  isSubmitted: false,
  bio: null,
  placementSize: '',
  isManaged: true,
};

// eslint-disable-next-line complexity
export const validateManaged = (
  values: ManagedFormValues,
  save: (...args: any) => Promise<any>,
  campaignId: string,
  enqueueSnackbar: (message: string, options: any) => void
) => {
  const errors = {};
  console.log('values.promoMessage : ', values.promoMessage);
  if (!values.brandName) {
    errors['brandName'] = 'Brand name is required.';
  }
  if (!values.name) {
    errors['name'] = 'Campaign name is required.';
  }
  if (!values.budget) {
    errors['budget'] = 'Budget is required.';
  }
  if (!values.description) {
    errors['description'] = 'Description is required.';
  }
  if (!values.industry) {
    errors['industry'] = 'Industry is required.';
  }
  if (!values.objective) {
    errors['objective'] = 'Objective is required.';
  }
  if (!values.bio === null) {
    errors['bio'] = 'Bio is required';
  }
  if (!values.placementSize) {
    errors['placementSize'] = 'Placement size is required';
  }
  if (!values.KPI) {
    errors['KPI'] = 'KPI is required';
  }
  if (!values.audience) {
    errors['audience'] = 'Audience is required.';
  }
  if (!values.geographies) {
    errors['geographies'] = 'Geographies is required.';
  }

  if (!values.websiteUrl) {
    errors['websiteUrl'] = 'Must provide a brand website.';
  }
  if (!values.startDate) {
    errors['startDate'] = 'Start date is required.';
  } else if (values.startDate < new Date()) {
    errors['startDate'] = 'Must be a future date.';
  }
  if (!values.endDate) {
    errors['endDate'] = 'End date is required.';
  } else if (values.startDate && values.endDate < values.startDate) {
    errors['endDate'] = 'End date must be after the Start date.';
  }
  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const parseQueryToFormValuesMedia = (data?: GetCampaignQuery) => {
  if (data) {
    const managed = {
      ...data.getCampaignById?.brief,
      ...data.getCampaignById?.creativeBrief,
    };
    const { id, __typename, ...rest } = managed;
    return rest as ManagedFormValues;
  } else {
    return;
  }
};

// Creative

export const CREATIVE_FORM_VALUES: CreativeFormValues = {
  websiteUrl: '',
  assetsUrl: '',
  focus: '',
  // eslint-disable-next-line no-undefined
  promoMessage: undefined,
  isSeasonal: null,
  isNewProduct: null,
};

// eslint-disable-next-line complexity
export const validateCreative = (
  values: CreativeFormValues,
  save: (...args: any) => Promise<any>,
  campaignId: string,
  enqueueSnackbar: (message: string, options: any) => void
) => {
  const errors = {};
  if (!values.websiteUrl) {
    errors['websiteUrl'] = 'WebsiteUrl is required.';
  }

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const parseQueryToFormValuesCreative = (data?: GetCampaignQuery) => {
  if (data?.getCampaignById?.creativeBrief) {
    const creative = { ...data.getCampaignById.creativeBrief };
    const { id, __typename, isSubmitted, ...rest } = creative;
    return rest as ManagedFormValues;
  } else {
    return;
  }
};
