import {
  AdludioTheme,
  CircularProgress,
  Grid,
  makeStyles,
} from '@adludio/components';

import React, { Dispatch, SetStateAction } from 'react';

import { MediaCampaignForm } from '../components/Forms/Media/MediaCamapaignForm';
import { ProgrammaticCampaignForm } from '../components/Forms/Programmatic/ProgrammaticCampaignForm';
import { RouteComponentProps } from '@reach/router';

const styles = makeStyles((theme: AdludioTheme) => ({
  container: {
    minHeight: '100vh',
    backgroundColor: theme.palette.secondary.contrastText,
    marginTop: '1.2rem',
  },
  autosave: {
    flexGrow: 0,
    position: 'sticky',
    bottom: 20,
    left: 2,
    borderRadius: '4px',
  },
  badgeSaving: {
    width: '16rem',
    boxShadow: theme.shadows[4],
    padding: '0.5rem',
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    borderRadius: '4px',
  },
  badgeSaved: {
    width: '16rem',
    padding: '0.5rem',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
}));

interface AddNewCampaignPageProps extends RouteComponentProps {
  campaignId: string;
  setBriefSubmitted?: Dispatch<SetStateAction<boolean>>;
  isMediaCampaign: boolean | null;
}

export function CampaignBriefEdit(props: AddNewCampaignPageProps): JSX.Element {
  const { container } = styles();

  return (
    <Grid
      container
      className={container}
      justify='flex-start'
      direction='column'
      alignItems='center'
    >
      {props.isMediaCampaign !== null ? (
        props.isMediaCampaign ? (
          <MediaCampaignForm id={props.campaignId} />
        ) : (
          <ProgrammaticCampaignForm id={props.campaignId} />
        )
      ) : (
        <Grid container item xs justifyContent='center' alignItems='center'>
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
}
