import React from 'react';
import { Grid } from '@adludio/components';
import { CampaignCard } from './CampaignCard';
import { Campaign } from '../models/campaign';

export interface Props {
  campaigns: Campaign[]
}

export function CampaignGrid(props: Props): JSX.Element {
  return (
    <Grid item xs container justify='flex-start' spacing={7}>
      {props.campaigns.map(campaign => (
        campaign.title !== null &&
        <Grid item key={campaign.title} xs={12} sm={6} md={4} lg={3} xl={3}>
          <CampaignCard campaign={campaign} />
        </Grid>
      ))}
    </Grid>
  );
}
