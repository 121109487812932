import React from 'react';

import { Grid, TextInput, Subtitle1, DatePicker } from '@adludio/components';
import { Box, FormHelperText } from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { mockCurrencies } from '../../../mock-data/mockCurrencies';
import { ErrorMessage, FormikProps } from 'formik';
import { mockIndustries } from '../../../mock-data/mockIndustries';
import { mockObjectives } from '../../../mock-data/mockObjectives';
import { campaignInfoStyles as styles } from '../styles';
import { mockKPIs } from '../../../mock-data/mockKPIs';
import { ManagedFormValues, ProgrammaticFormValues } from '../types';

export const CampaignInformationScreen = ({
  formikProps,
}: {
  formikProps: FormikProps<ProgrammaticFormValues> | FormikProps<ManagedFormValues>;
}) => {
  const { errors, values, handleChange, setFieldValue } = formikProps;
  const { column, dropdown, menuPaper, helperText, purple } = styles();

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 14);

  return (
    <Box width='100%' justifyContent='center' mb='4rem'>
      <Grid className={column} container justify='center' xs={12}>
        <Grid item xs={8}>
          <Box mt='3rem'>
            <Subtitle1 className={purple}>Your Brand</Subtitle1>
          </Box>
        </Grid>
        <Grid container xs={8}>
          <TextInput
            id='brandName'
            name='brandName'
            variant='outlined'
            label='Brand Name'
            value={values.brandName}
            onChange={handleChange}
            required
            error={!!errors.brandName}
            helperText={errors.brandName}
          />
        </Grid>
        <Grid container xs={8}>
          <TextInput
            id='name'
            name='name'
            variant='outlined'
            label='Campaign Name'
            value={values.name}
            onChange={handleChange}
            required
            error={!!errors.name}
            helperText={errors.name}
          />
          <ErrorMessage name='required' />
        </Grid>
        <Grid item xs={8}>
          <Box mt='1.5rem'>
            <Subtitle1 className={purple}>Budget</Subtitle1>
          </Box>
        </Grid>
        <Grid container item xs={8} justifyContent='space-between' spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl variant='outlined' className={dropdown}>
              <InputLabel id='currency-select-label'>Currency*</InputLabel>
              <Select
                MenuProps={{ classes: { paper: menuPaper } }}
                value={values.currency}
                name='currency'
                required
                labelId='currency-select-label'
                label='currency'
                onChange={handleChange}
              >
                {mockCurrencies.map((c) => (
                  <MenuItem value={c.value} key={c.key}>
                    {c.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8} md={8} lg={9}>
            <TextInput
              required
              variant='outlined'
              type='number'
              name='budget'
              label='Campaign budget'
              value={values.budget}
              onChange={handleChange}
              error={!!errors.budget}
              helperText={errors.budget}
            />
            <ErrorMessage name='required' />
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Box mt='1rem' mb='1.5rem'>
            <Subtitle1 className={purple}>Campaign information</Subtitle1>
          </Box>
          <TextInput
            required
            variant='outlined'
            label='Description'
            rows={4}
            multiline
            name='description'
            FormHelperTextProps={{
              className: helperText,
            }}
            inputProps={{ maxLength: 1000 }}
            value={values.description}
            onChange={handleChange}
            error={!!errors.description}
            helperText={
              errors.description ?? 'Tell us a bit about what you want to do'
            }
          />
        </Grid>
        <Grid item xs={8}>
          <FormControl
            variant='outlined'
            required
            className={dropdown}
            error={!!errors.industry}
          >
            <InputLabel id='industry-select-label'>Industry</InputLabel>
            <Select
              fullWidth
              required
              MenuProps={{ classes: { paper: menuPaper } }}
              value={values.industry}
              onChange={handleChange}
              name='industry'
              labelId='industry-select-label'
              label='Industry'
            >
              {mockIndustries.map((c) => (
                <MenuItem value={c.value} key={c.key}>
                  {c.label}
                </MenuItem>
              ))}
            </Select>
            {errors.industry && (
              <FormHelperText>{errors.industry}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid container xs={8}>
          <Grid xs={5}>
            <Box width='90%' mr='1.5rem'>
              <DatePicker
                required
                inputVariant='outlined'
                fullWidth
                format='DD-MM-YYYY'
                label='Start Date'
                minDate={values.startDate || minDate}
                autoOk
                name='startDate'
                value={values.startDate}
                onChange={(time) =>
                  setFieldValue('startDate', time.toDate() as Date)
                }
                error={!!errors.startDate}
                helperText={errors.startDate}
              />
            </Box>
          </Grid>
          <Grid container justify='flex-end' xs={5}>
            <Box width='100%'>
              <DatePicker
                required
                name='endDate'
                inputVariant='outlined'
                fullWidth
                format='DD-MM-YYYY'
                label='End Date'
                autoOk
                minDate={values.startDate ?? minDate}
                value={values.endDate}
                onChange={(time) =>
                  setFieldValue('endDate', time.toDate() as Date)
                }
                error={!!errors.endDate}
                helperText={errors.endDate}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <FormControl
            variant='outlined'
            required
            className={dropdown}
            error={!!errors.objective}
          >
            <InputLabel id='Objective-select-label'>Objective</InputLabel>
            <Select
              MenuProps={{ classes: { paper: menuPaper } }}
              value={values.objective}
              required
              name='objective'
              onChange={handleChange}
              labelId='Objective-select-label'
              label='Objective'
              error={!!errors.objective}
            >
              {mockObjectives.map((c) => (
                <MenuItem value={c.value} key={c.key}>
                  {c.label}
                </MenuItem>
              ))}
            </Select>
            {errors.objective && (
              <FormHelperText>{errors.objective}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <FormControl
            variant='outlined'
            required
            className={dropdown}
            error={!!errors.objective}
          >
            <InputLabel id='kpi-select-label'>KPI</InputLabel>
            <Select
              MenuProps={{ classes: { paper: menuPaper } }}
              value={values.KPI}
              required
              error={!!errors.KPI}
              name='KPI'
              onChange={handleChange}
              labelId='KPI-select-label'
              label='KPI'
            >
              {mockKPIs.map((c) => (
                <MenuItem value={c.value} key={c.key}>
                  {c.label}
                </MenuItem>
              ))}
            </Select>
            {errors.KPI && <FormHelperText>{errors.KPI}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};
