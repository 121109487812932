import * as React from 'react';

import {
  AcceptCampaignPlanQuery,
  UpdateDocumentStatusMutation,
  UserType,
  useAcceptCampaignPlanLazyQuery,
  useEmailResolverLazyQuery,
  useUpdateDocumentStatusMutation,
} from '../../generated/graphql';
/* eslint-disable complexity */
import {
  AdludioTheme,
  Body1,
  Button,
  Caption,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  H5,
  IconButton,
  makeStyles,
} from '@adludio/components';
import { Checkbox, FormControlLabel, Link } from '@material-ui/core';

import { ApolloError } from '@apollo/client';
import CloseIcon from '@material-ui/icons/Close';
import { FileStatus } from '../../models/document';
import { useDownloadTermsAndConditions } from '../../helpers/downloadTermsConditions';
import { useState } from 'react';

interface AcceptPlanModalProps {
  isOpen: boolean;
  onClose: () => void;
  campaignId: string;
  download: () => void;
  documentId: string;
}

const styles = makeStyles((theme: AdludioTheme) => ({
  content: {
    padding: '0.8vh 2vw 0.5vh 2vw',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
  paper: {
    minWidth: '700px !important',
    [theme.breakpoints.down('sm')]: {
      minWidth: '82% !important',
    },
    [theme.breakpoints.only('xs')]: {
      minWidth: '100% !important',
    },
    maxWidth: 'min-content',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: '20px',
  },
  support: {
    marginTop: '5px',
    display: 'flex',
  },
  dialog: {
    margin: '3vh 0vw 5vh 0vw',
  },
  prompt: {
    paddingBottom: '8px',
  },
  title: {
    marginTop: '20px',
  },
  closeButton: {
    position: 'absolute',
    left: '93%',
    top: '1%',
    color: 'gray',
    '&:hover': {
      backgroundColor: 'lightgrey',
    },
  },
  links: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
  },
}));

const AcceptPlanModal = ({
  isOpen,
  onClose,
  documentId,
  download,
  campaignId,
}: AcceptPlanModalProps) => {
  const {
    actions,
    content,
    support,
    closeButton,
    links,
    prompt,
    dialog,
    paper,
    title,
  } = styles();
  const [isAgreeChecked, setIsAgreeChecked] = useState(false);
  const [isAgreedTC, setIsAgreedTC] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const { downloadDocument } = useDownloadTermsAndConditions();

  const [sendEmail] = useEmailResolverLazyQuery({
    variables: {
      campaignId: campaignId,
      templateName: 'CampaignPlanAccepted',
      userType: UserType.Internal,
    },
  });

  const handleAccepted = (data: AcceptCampaignPlanQuery) => {
    setIsSuccess(true);
    sendEmail();
  };
  const handleAcceptError = (acceptError: ApolloError) => {
    console.log('Accept Error', acceptError);
    setIsError(true);
  };
  const [acceptPlan] = useAcceptCampaignPlanLazyQuery({
    onCompleted: handleAccepted,
    onError: handleAcceptError,
  });
  const handleUpdated = (data: UpdateDocumentStatusMutation) => {
    acceptPlan({ variables: { campaignId } });
  };
  const [updateDocumentStatusMutation] = useUpdateDocumentStatusMutation({
    onCompleted: handleUpdated,
  });

  const handleSubmit = async () => {
    if (isAgreeChecked && isAgreedTC) {
      updateDocumentStatusMutation({
        variables: { id: documentId, status: FileStatus.accepted },
      }).catch(() => {
        console.log('Error updating document status');
        setIsError(true);
      });
    }
  };

  return (
    <Dialog PaperProps={{ className: paper }} open={isOpen} onClose={onClose}>
      <DialogTitle className={title}>
        <H5 align='center'>
          {isSuccess ? 'You’re all done!' : 'Accept your Campaign Plan'}
        </H5>
      </DialogTitle>
      <IconButton
        onClick={onClose}
        color='primary'
        aria-label='close-accept-plan-dialog'
        label=''
        className={closeButton}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className={content}>
        {!isSuccess ? (
          <Body1 className={prompt}>
            Please{' '}
            <Link
              component='button'
              variant='body1'
              className={links}
              download
              onClick={() => download()}
            >
              download
            </Link>{' '}
            and go through your campaign plan before accepting it.
          </Body1>
        ) : (
          <Body1 className={dialog}>
            Our designers will start to work on your campaign straight away. You
            will be notified via email when they are ready!
          </Body1>
        )}
        {!isSuccess && !isError && (
          <>
            <FormControlLabel
              label={
                <Body1>
                  I have downloaded the campaign plan and I agree to it.
                </Body1>
              }
              control={
                <Checkbox
                  onClick={(e) => {
                    setIsAgreeChecked(!isAgreeChecked);
                  }}
                  color='primary'
                  checked={isAgreeChecked}
                  value='labeled'
                />
              }
            />
            <FormControlLabel
              label={
                <Body1>
                  Agree to the&nbsp;
                  <Link
                    className={links}
                    onClick={(
                      e:
                        | React.MouseEvent<HTMLSpanElement, MouseEvent>
                        | React.MouseEvent<HTMLAnchorElement, MouseEvent>
                    ) => {
                      downloadDocument();
                      e.preventDefault();
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                    }}
                  >
                    Terms and Conditions
                  </Link>
                  &nbsp; of the campaign to accept campaign plan
                </Body1>
              }
              control={
                <Checkbox
                  onClick={() => {
                    setIsAgreedTC(!isAgreedTC);
                  }}
                  color='primary'
                  checked={isAgreedTC}
                  value='labeled'
                />
              }
            />
          </>
        )}
        <DialogActions className={actions}>
          {!isSuccess && (
            <>
              <Button
                disabled={!isAgreedTC || !isAgreeChecked}
                onClick={handleSubmit}
                variant='contained'
                size='large'
              >
                Accept
              </Button>
              <Caption className={support}>
                Not happy with the plan?&nbsp;
                <Link
                  className={links}
                  onClick={() =>
                    window.open('mailto:trish.heatley@adludio.com')
                  }
                >
                  <Caption>Speak to us</Caption>
                </Link>
              </Caption>
            </>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AcceptPlanModal;
