import {
  Body1,
  Body2,
  Grid,
  H6,
  Subtitle1,
  Box,
  makeStyles,
  AdludioTheme,
  useMediaQuery,
} from '@adludio/components';
import { CreativeBriefOverviewRead } from '../CreativeBrief/CreativeBriefOverviewRead';
import { NewCampaignFormContext } from '../../../context/NewCampaignFormContext';
/* eslint-disable complexity */
import React from 'react';
import { Theme } from '@material-ui/core';

const styles = makeStyles((theme: AdludioTheme) => ({
  purple: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  divider: {
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
  },
  dividerMobile: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    marginTop: '1.5rem',
  },
}));

export function CampaignBriefOverviewRead(): JSX.Element {
  const { purple, divider, dividerMobile } = styles();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );
  const formattedDate = (date: any) => {
    if (typeof date === 'object') {
      const day = date ? date.toISOString().split('T')[0] : '-';
      return day;
    }
    const day = date ? date.split('T') : '-';
    return day[0];
  };
  return (
    <NewCampaignFormContext.Consumer>
      {(context) => (
        <Grid container direction='row' justifyContent='space-around'>
          <Grid container xs={12} sm={5} spacing={4}>
            <Grid item xs={12}>
              <H6 color='secondary'>Campaign Information</H6>
            </Grid>
            <Grid item xs={12}>
              <Subtitle1 className={purple}>Brand</Subtitle1>
            </Grid>
            <Grid item xs={12}>
              <Body2 color='textSecondary'>Brand name</Body2>
              <Body1>
                {context.state.campaignData.brandName || 'Not specified'}
              </Body1>
            </Grid>
            <Grid item xs={12}>
              <Body2 color='textSecondary'>Campaign name</Body2>
              <Body1>
                {context.state.campaignData.name || 'Not specified'}
              </Body1>
            </Grid>
            <Grid item xs={12}>
              <Subtitle1 className={purple}>Budget</Subtitle1>
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6}>
                <Body2 color='textSecondary'>Currency</Body2>
                <Body1>
                  {context.state.campaignData.currency || 'Not specified'}
                </Body1>
              </Grid>
              <Grid item xs={6}>
                <Body2 color='textSecondary'>Budget</Body2>
                <Body1>
                  {context.state.campaignData.budget || 'Not specified'}
                </Body1>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Subtitle1 className={purple}>Campaign Information</Subtitle1>
            </Grid>
            <Grid item xs={12}>
              <Body2 color='textSecondary'>Description</Body2>
              <Body1>
                {context.state.campaignData.description || 'Not specified'}
              </Body1>
            </Grid>
            <Grid item xs={12}>
              <Body2 color='textSecondary'>Industry</Body2>
              <Body1>
                {context.state.campaignData.industry || 'Not specified'}
              </Body1>
            </Grid>
            <Grid item xs={12} container direction='row'>
              <Grid item xs={12}>
                <Body2 color='textSecondary'>Start date</Body2>
                <Body1>
                  {formattedDate(context.state.campaignData.startDate)}
                </Body1>
              </Grid>
              <Grid item xs={12}>
                <Body2 color='textSecondary'>End date</Body2>
                <Body1>
                  {formattedDate(context.state.campaignData.endDate)}
                </Body1>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Body2 color='textSecondary'>Objective</Body2>
              <Body1>
                {context.state.campaignData.objective || 'Not specified'}
              </Body1>
            </Grid>
            <Grid item xs={12}>
              <Body2 color='textSecondary'>KPI</Body2>
              <Body1>{context.state.campaignData.KPI || 'Not specified'}</Body1>
            </Grid>
          </Grid>
          <Grid
            className={isSmallScreen ? dividerMobile : divider}
            container
            xs={12}
            sm={6}
            justifyContent='flex-end'
            alignItems='flex-start'
          >
            <Grid
              container
              direction='column'
              xs={12}
              sm={10}
              spacing={isSmallScreen ? 2 : 4}
            >
              <Grid item xs>
                <Box mt={isSmallScreen ? '1.5rem' : 0}>
                  <H6 color='secondary'>Serving & Targeting</H6>
                </Box>
              </Grid>
              <Grid item xs>
                <Subtitle1 className={purple}>Audience</Subtitle1>
              </Grid>
              <Grid item xs>
                <Body2 color='textSecondary'>Audience</Body2>
                <Body1>
                  {context.state.campaignData.audience || 'Not specified'}
                </Body1>
              </Grid>
              <Grid item xs>
                <Subtitle1 className={purple}>Serving Information</Subtitle1>
              </Grid>
              <Grid item xs>
                <Body2 color='textSecondary'>Serving Locations</Body2>
                {context.state.campaignData.geographies
                  ?.split(',')
                  .map((geo) => <Body1 key={geo}>{geo}</Body1>) || (
                    <Body1>Not specified</Body1>
                  )}
              </Grid>
              {context.state.campaignData.isManaged ? (
                <>
                  <Grid item xs>
                    <Body2 color='textSecondary'>BIO Required</Body2>
                    <Body1>
                      {context.state.campaignData.bio ? 'Yes' : 'No'}
                    </Body1>
                  </Grid>
                  <Grid item xs>
                    <Body2 color='textSecondary'>Placement Sizes</Body2>
                    <Body1>
                      {context.state.campaignData.placementSize ||
                        'Not specified'}
                    </Body1>
                  </Grid>
                  <Grid item xs>
                    <CreativeBriefOverviewRead />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs>
                    <Body2 color='textSecondary'>DSP</Body2>
                    <Body1>
                      {context.state.campaignData.DSP || 'Not specified'}
                    </Body1>
                  </Grid>
                  <Grid item xs>
                    <Body2 color='textSecondary'>Seat ID</Body2>
                    <Body1>
                      {context.state.campaignData.seatId || 'Not specified'}
                    </Body1>
                  </Grid>
                </>
              )}
              <Grid item xs>
                <Subtitle1 className={purple}>Site list</Subtitle1>
                {context.state.campaignData.sitelistUrl ? (
                  context.state.campaignData.sitelistUrl
                    .split(',')
                    .map((a) => <Body1 key={a}>{a}</Body1>)
                ) : (
                  <Body1>Not specified</Body1>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </NewCampaignFormContext.Consumer>
  );
}
