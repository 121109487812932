import {
  AdludioTheme,
  Body2,
  Box,
  CircularProgress,
  Grid,
  Subtitle1,
  makeStyles,
} from '@adludio/components';
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable complexity */
import React, { useContext, useEffect, useRef, useState } from 'react';

import { AuthContext } from '@adludio/react-sso/dist';
import CampaignBrief from '../../components/CampaignBrief';
import { CampaignHeader } from '../../components/CampaignHeader/CampaignHeader';
import { CampaignPlanPage } from '../CampaignPlanPage';
import { Dots } from '../../components/Dots';
import DottedSVG from '../../assets/Dotted-element-reverse.svg';
import EmptyPage from '../../components/EmptyPage';
import { ErrorPage } from './../ErrorPage';
import FilesPage from '../FilesPage';
import Preview from '@adludio/components/dist/Preview';
import { PreviewProvider } from '@adludio/components/dist/Preview';
import Report from '../../repo-board';
import { RouteComponentProps } from '@reach/router';
import { SideNav } from '../../components/CampaignNav';
import UploadAdunit, { AdunitData } from '../../components/UploadAdunit';
import { getBriefLink } from '../../helpers/getBriefLink';
import { getCampaignLink } from '../../helpers/getCampaignLink';
import { useCreateAdunitMutation } from '../../generated/graphql';
import { useGetCampaignQuery } from '../../generated/graphql';

export interface ViewSingleBriefProps extends RouteComponentProps {
  env: 'development' | 'staging' | 'production' | 'testing';
  campaignId?: string;
  state?: string;
}
interface User {
  name: string;
  email: string;
}
const useStyles = makeStyles((theme: AdludioTheme) => ({
  root: {
    backgroundColor: theme.palette.secondary.contrastText,
    zIndex: -3,
    padding: '0px',
    width: '100%',
    minHeight: '100vh',
    paddingBottom: '3.5em',
    transition: ({
      expandedNav,
      animate,
    }: {
      expandedNav: boolean;
      animate: boolean;
    }) => (animate === true ? 'all 0.3s ease-out' : 'unset'),
    [theme.breakpoints.up('md')]: {
      paddingBottom: '0px',
      zIndex: -2,
      width: ({
        expandedNav,
        animate,
      }: {
        expandedNav: boolean;
        animate: boolean;
      }) => (expandedNav ? 'calc(100% - 170px)' : 'calc(100% - 65px)'),
      marginLeft: ({
        expandedNav,
        animate,
      }: {
        expandedNav: boolean;
        animate: boolean;
      }) => (expandedNav ? '170px' : '65px'),
    },
  },
  loadingContainer: {
    height: '100vh',
  },
  reporting: {
    margin: '0px 1em',
    width: '98%',
  },
  dotsDiv: {
    position: 'absolute',
    zIndex: 1,
    marginTop: '6rem',
  },
  unitBox: {
    maxWidth: 'min-content',
  },
}));

export function BriefPage(props: ViewSingleBriefProps): JSX.Element {
  // GraphQL hooks
  const { data, loading, error } = useGetCampaignQuery({
    variables: { id: props.campaignId! },
    fetchPolicy: 'network-only',
  });
  const { auth } = useContext(AuthContext);
  const [createAdunit, { data: newAdunit }] = useCreateAdunitMutation();
  const [creativeBriefSubmitted, setCreativeBriefSubmitted] = useState(false);
  const [briefSubmitted, setBriefSubmitted] = useState(false);

  // STATES
  const [team, setTeam] = useState<User[]>([]);
  const [adunits, setAdunits] = useState<string[]>([]);
  const [adunitData, setAdunitData] = useState<AdunitData[]>([]);
  const [selectedItem, setSelectedItem] = useState(props.state);
  const [expandedNav, setExpandedNav] = useState(false);
  const [adunitVersion, setAdunitVersion] = useState(new Map());
  const adunitVersionRef = useRef(adunitVersion);
  const [animate, setAnimate] = useState(false);
  const animationStarted = useRef<boolean | null>(null);
  // STYLES
  const { root, loadingContainer, reporting, dotsDiv, unitBox } = useStyles({
    expandedNav,
    animate,
  });

  // OTHER VARS

  const isAllowed = auth.user?.role === 'admin' || auth.user?.role === 'cm';
  const campaignName =
    data?.getCampaignById?.title ??
    (data?.getCampaignById?.brief?.name as string);
  const manualCampaign =
    (data?.getCampaignById?.brief?.name === null ||
      data?.getCampaignById?.brief === null) &&
    data?.getCampaignById?.title !== null;
  const isNotOnProduction = data?.getCampaignById?.title === null;
  const getEmailStatus = () => {
    if (data?.getCampaignById?.status) {
      return getCampaignLink(
        data.getCampaignById.brief?.startDate,
        data.getCampaignById.brief?.endDate,
        data.getCampaignById.status,
        data.getCampaignById.adunits?.length ?? 0
      );
    } else {
      return getBriefLink(
        data?.getCampaignById?.brief?.isSubmitted,
        data?.getCampaignById?.creativeBrief?.isSubmitted ?? false,
        data?.getCampaignById?.brief?.isManaged ?? false
      );
    }
  };

  const getVersion = (adunit: string) =>
    fetch(`https://wat.adludio.com/games/${adunit}/active`, {
      method: 'GET',
    })
      .then((response) => response.text().then((res) => res))
      .catch((e) => null);

  const handleExpand = (expand: boolean) => {
    setExpandedNav(expand);
    if (!animationStarted.current) {
      setAnimate(true);
      animationStarted.current = true;
    }
  };

  // useEffects
  useEffect(() => {
    if (data?.getCampaignById?.adunits && adunits.length < 1) {
      const gameNames: string[] = [];
      const gameData: {
        title: string;
        createdAt: Date;
        id: string;
        width: number;
        height: number;
      }[] = [];
      data.getCampaignById.adunits.map((a) => {
        a?.adunitIterations?.map((i) => {
          gameNames.push(i?.creativeChanges?.gameName!);
          gameData.push({
            title: a?.title!,
            createdAt: a?.createdAt!,
            id: a.id!,
            width: a?.width!,
            height: a?.height!,
          });
        });
      });
      setAdunitData(gameData);
      setAdunits(gameNames);
    }

    if (newAdunit?.createAdunit?.adunitIterations) {
      let newGameName = '';
      newAdunit.createAdunit.adunitIterations?.map(
        (a) => (newGameName = a?.creativeChanges?.gameName!)
      );
      setAdunits([...adunits, newGameName]);
      setAdunitData([
        ...adunitData,
        {
          title: newAdunit.createAdunit.slug!,
          createdAt: new Date(),
          id: newAdunit.createAdunit.id!,
          width: newAdunit.createAdunit.width!,
          height: newAdunit.createAdunit.height!,
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, newAdunit]);

  useEffect(() => {
    if (data) {
      setTeam(data.getCampaignById?.team?.map((user) => user) as User[] ?? [] as User[]);
      setBriefSubmitted(!!data?.getCampaignById?.brief?.isSubmitted);
      setCreativeBriefSubmitted(
        !!data?.getCampaignById?.creativeBrief?.isSubmitted
      );
    }
  }, [data]);

  useEffect(() => {
    setSelectedItem(props.state);
    if (animate) setAnimate(false);
    if (props.state === 'reporting') {
      setExpandedNav(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.state]);

  useEffect(() => {
    if (!expandedNav && props.state !== 'reporting') setAnimate(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedNav]);

  useEffect(() => {
    for (const key of adunits) {
      getVersion(key)
        .then((res) => {
          const newVersions = new Map(adunitVersionRef.current).set(key, res);
          adunitVersionRef.current = newVersions;
          setAdunitVersion(newVersions);
        })
        .catch(console.log);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adunits]);

  return error ? (
    <ErrorPage />
  ) : (
    <PreviewProvider>
      {loading ? (
        <Grid
          className={loadingContainer}
          container
          item
          xs
          justify='center'
          alignItems='center'
        >
          <Grid item xs={1}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Grid container direction='column' xs={12} className={root}>
          <SideNav
            status={data?.getCampaignById?.status}
            briefSubmitted={briefSubmitted}
            manualCampaign={manualCampaign}
            creativeSubmitted={creativeBriefSubmitted}
            selectedItem={selectedItem ?? 'brief'}
            setSelectedItem={setSelectedItem}
            campaignId={props.campaignId}
            expandedNav={expandedNav}
            setExpandedNav={handleExpand}
            startDate={
              data?.getCampaignById?.brief?.startDate
                ? new Date(data?.getCampaignById?.brief?.startDate)
                : null
            }
            managedCampaign={data?.getCampaignById?.brief?.isManaged ?? false}
          />
          {/* Campaign info container */}
          {selectedItem !== 'reporting' && (
            <Box py='20px' mt='1rem'>
              <CampaignHeader
                endDate={data?.getCampaignById?.brief?.endDate}
                startDate={data?.getCampaignById?.brief?.startDate}
                team={team}
                campaignId={data?.getCampaignById?.id || ''}
                campaignStatus={getEmailStatus()}
                env={props.env}
                campaignName={campaignName}
              />
              <Box className={dotsDiv}>
                <img src={DottedSVG} width='70%' />
              </Box>
            </Box>
          )}
          <Grid item container xs={12} style={{ zIndex: 2 }}>
            {data && (
              <Grid container item direction='row' justify='center'>
                <>
                  {selectedItem === 'brief' &&
                    (!manualCampaign ? (
                      <CampaignBrief
                        campaignId={props.campaignId}
                        briefSubmitted={briefSubmitted}
                        setBriefSubmitted={setBriefSubmitted}
                        creativeSubmitted={creativeBriefSubmitted}
                        setCreativeBriefSubmitted={setCreativeBriefSubmitted}
                        campaignStatus={getEmailStatus()}
                      />
                    ) : (
                      <ErrorPage />
                    ))}
                  {selectedItem === 'campaignPlan' && (
                    <CampaignPlanPage campaignId={props.campaignId!} />
                  )}
                  {selectedItem === 'files' && (
                    <FilesPage campaignId={props.campaignId!} />
                  )}
                </>
                {(!isNotOnProduction || isAllowed) && (
                  <>
                    {selectedItem === 'reporting' && (
                      <Grid
                        container
                        item
                        xs={12}
                        direction='row'
                        justify='center'
                        className={reporting}
                      >
                        <Report
                          campaignId={props.campaignId}
                          team={team}
                          campaignStatus={getEmailStatus()}
                          env={props.env}
                          campaignName={campaignName}
                        />
                      </Grid>
                    )}
                    {selectedItem === 'creativeProduction' && (
                      <Grid
                        container
                        item
                        xs={10}
                        direction='row'
                        justify='center'
                        spacing={3}
                      >
                        <Grid container direction={'row'}>
                          {auth.user?.role === 'designer' || isAllowed ? (
                            <UploadAdunit
                              startDate={data.getCampaignById?.brief?.startDate}
                              campaignId={props.campaignId}
                              createAdunit={createAdunit}
                              adunits={adunits}
                              adunitVersion={adunitVersion}
                              adunitData={adunitData}
                              setAdunitData={setAdunitData}
                            />
                          ) : adunits.length >= 1 ? (
                            adunits.map((adunit: string, key) => {
                              const adunitVer = adunitVersion.get(adunit);
                              return (
                                adunitVer && (
                                  <Grid
                                    container
                                    className={unitBox}
                                    key={adunit}
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    xl={3}
                                  >
                                    <Box
                                      width='100%'
                                      mt='2rem'
                                      mb='1rem'
                                      pr='2.5rem'
                                    >
                                      <Preview
                                        src={`https://wat.adludio.com/games/${adunit}/${adunitVer}/index.html`}
                                        height={
                                          adunitData[key].height > 1150
                                            ? 1150
                                            : adunitData[key].height
                                        }
                                        width={
                                          adunitData[key].width > 1150
                                            ? 1150
                                            : adunitData[key].width
                                        }
                                      />
                                    </Box>

                                    <Subtitle1 style={{ fontWeight: 500 }}>
                                      {adunitData[key].title}
                                    </Subtitle1>
                                    <Body2>
                                      {new Date(
                                        adunitData[key].createdAt
                                      ).toLocaleDateString()}
                                    </Body2>
                                  </Grid>
                                )
                              );
                            })
                          ) : (
                            <EmptyPage isCreative />
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            )}
          </Grid>
          {selectedItem !== 'reporting' && (
            <Grid container justifyContent='flex-end'>
              <Dots />
            </Grid>
          )}
        </Grid>
      )}
    </PreviewProvider>
  );
}
