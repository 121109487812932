import React from 'react';
import {
  SaveCampaignBriefInput,
  SaveCreativeBriefInput,
} from '../generated/graphql';
import { Action } from '../models/action';

export interface CampaignFormData {
  campaignData: SaveCampaignBriefInput;
  creativeData?: SaveCreativeBriefInput;
}

export interface Context {
  state: CampaignFormData;
  dispatch?: React.Dispatch<Action>;
}

export const newCampaignFormContextDefaultValue: Context = {
  state: {
    campaignData: {},
    creativeData: {},
  },
};

export const NewCampaignFormContext = React.createContext<Context>(
  newCampaignFormContextDefaultValue
);
