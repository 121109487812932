import {
  AdludioTheme,
  Box,
  Button,
  Divider,
  Grid,
  Subtitle1,
  Tab,
  TabPanel,
  Tabs,
  makeStyles,
  useSnackbar,
} from '@adludio/components';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { UserType, useEmailResolverLazyQuery } from '../../generated/graphql';

import AddAdunitModal from '../Modals/AddAdunit';
import { AdunitInfo } from '../AdunitInfo';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Preview from '@adludio/components/dist/Preview';
import UploadAdunitSVG from '../../assets/AdUnits.svg';

export interface AdunitData {
  title: string;
  createdAt: Date;
  id: string;
  width: number;
  height: number;
}


interface UploadAdunitProps {
  campaignId?: string;
  createAdunit: any;
  adunits: string[];
  startDate: string;
  adunitVersion: Map<string, string>;
  adunitData: AdunitData[];
  setAdunitData: Dispatch<
    SetStateAction<AdunitData[]>
  >;
}

const styles = makeStyles((theme: AdludioTheme) => ({
  uploadButton: {
    background: theme.palette.primary.main,
    padding: '0.5rem 1rem 0.5rem 1rem',
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  adunitSVG: {
    maxWidth: '575px',
    maxHeight: '470px',
  },
  unitBox: {
    maxWidth: 'min-content',
  },
}));

const UploadAdunit = (props: UploadAdunitProps) => {
  const [value, setValue] = useState(0);
  const [addAdunitOpen, setAddAdunitOpen] = useState(false);
  const [error, setIsError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [sendEmail] = useEmailResolverLazyQuery({
    variables: {
      campaignId: props.campaignId!,
      templateName: 'AdUnitUploaded',
      userType: UserType.External,
      otherFields: JSON.stringify({
        startDate: new Date(props.startDate).toLocaleDateString('en-uk', {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
      }),
    },
  });

  const { uploadButton, adunitSVG, unitBox } = styles();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const acceptFunction = (
    gameKey: string,
    title: string,
    width: number,
    height: number
  ) => {
    props
      .createAdunit({
        variables: {
          gameKey,
          title,
          campaignId: props.campaignId!,
          width: width,
          height: height,
        },
      })
      .then(sendEmail)
      .then(() => setAddAdunitOpen(false))
      .catch(() => {
        setIsError(true);
        enqueueSnackbar('Error occurred couldn\'t upload the ad unit', {
          variant: 'error',
        });
      });
    if (!error) {
      setAddAdunitOpen(false);
    }
  };

  /* size in pixels from demoType table*/

  return (
    <>
      <Grid container xs={12} justifyContent='flex-start' direction='column'>
        <Grid xs={10}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label='Upload an ad' />
            <Tab label='Signed off Ads' disabled={props.adunits.length === 0} />
          </Tabs>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Divider style={{ width: '100%' }} />
      </Grid>
      <Grid container xs={12} justifyContent='flex-start' direction='column'>
        <TabPanel currentTab={value} index={0}>
          <Grid container justifyContent='center' item xs={12}>
            {props.adunits.length === 0 && (
              <Grid xs={12}>
                <Box mt='3rem'>
                  <Subtitle1 align='center'>
                    You haven’t uploaded any ad yet
                  </Subtitle1>
                </Box>
              </Grid>
            )}
            <Box mt='2rem' mb='3rem'>
              <Button
                onClick={() => setAddAdunitOpen(true)}
                className={uploadButton}
                endIcon={<CloudUpload />}
              >
                Upload Ads
              </Button>
            </Box>
          </Grid>
          <Grid container justifyContent='center' item xs={12}>
            <img src={UploadAdunitSVG} height='50%' className={adunitSVG} />
          </Grid>
          <AddAdunitModal
            isOpen={addAdunitOpen}
            acceptFn={acceptFunction}
            onClose={() => {
              setAddAdunitOpen(false);
            }}
          />
        </TabPanel>
        <TabPanel currentTab={value} index={1}>
          <Grid container xs={12} direction='row'>
            {props.adunits.length >= 1 &&
              props.adunits.map((adunit: string, key) => {
                const adunitVer = props.adunitVersion.get(adunit);
                return (
                  adunitVer && (
                    <Grid
                      container
                      className={unitBox}
                      key={adunit}
                      xs={12}
                      md={6}
                      lg={4}
                      xl={3}
                    >
                      <Grid item xs>
                        <Box width='100%' mt='2rem' mb='1rem' pr='2.5rem'>
                          <Preview
                            src={`https://wat.adludio.com/games/${adunit}/${adunitVer}/index.html`}
                            height={
                              props.adunitData[key].height > 1150
                                ? 1150
                                : props.adunitData[key].height
                            }
                            width={
                              props.adunitData[key].width > 1150
                                ? 1150
                                : props.adunitData[key].width
                            }
                          />
                        </Box>
                      </Grid>
                      <AdunitInfo
                        adunitData={props.adunitData[key]}
                        setAdunitData={props.setAdunitData}
                      />
                    </Grid>
                  )
                );
              })}
          </Grid>
        </TabPanel>
      </Grid>
    </>
  );
};

export default UploadAdunit;
