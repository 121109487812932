import {
  AdludioTheme,
  Box,
  Caption,
  Grid,
  makeStyles,
} from '@adludio/components';

import { Icon } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: AdludioTheme) => ({
  mainDiv: {
    borderRadius: '15px',
    color: 'white',
  },
  brief: {
    background: theme.palette.warning.main,
  },
  campaignPlan: {
    background: theme.palette.primary.dark,
  },
  production: {
    background: theme.palette.info.main,
  },
  serving: {
    background: theme.palette.success.dark,
  },
  completed: {
    background: theme.palette.secondary.main,
  },
  pendingLaunch: {
    background: theme.palette.primary.main,
  },
}));

export const StatusBadge = (props: { status: string }) => {
  const {
    brief,
    campaignPlan,
    mainDiv,
    production,
    serving,
    completed,
    pendingLaunch,
  } = useStyles();

  const getStatusDetailsFromStatus = () => {
    switch (props.status) {
      case 'brief':
        return { title: 'DRAFT', style: brief, icon: 'insert_drive_file' };
      case 'campaignPlan':
        return { title: 'BUILDING PLAN', style: campaignPlan, icon: 'article' };
      case 'production':
        return { title: 'PRODUCTION', style: production, icon: 'brush' };
      case 'pendingLaunch':
        return { title: 'PENDING LAUNCH', style: pendingLaunch, icon: 'timer' };
      case 'serving':
        return { title: 'SERVING', style: serving, icon: 'important_devices' };
      default:
        return { title: 'COMPLETED', style: completed, icon: 'done' };
    }
  };

  return (
    <Box className={`${mainDiv} ${getStatusDetailsFromStatus().style}`}>
      <Grid container direction='row' alignItems='center'>
        <Box mx='0.5rem' alignContent='center' mt='0.2rem' alignItems='center'>
          <Icon fontSize='small'>{getStatusDetailsFromStatus().icon}</Icon>
        </Box>
        <Box mr='0.5rem'>
          <Caption>{getStatusDetailsFromStatus().title}</Caption>
        </Box>
      </Grid>
    </Box>
  );
};
