import { AdludioTheme, makeStyles } from '@adludio/components';

export const programmaticCampaignStyles = makeStyles<
  AdludioTheme,
  { isEditing?: boolean }
>((theme) => ({
  column: {
    display: 'flex',
    rowGap: '20px',
  },
  title: {
    height: '90px',
    margin: '20px 0 35px',
  },
  stepper: {
    width: '67%',
    padding: '24px 30px',
    boxSizing: 'border-box',
    marginTop: ({ isEditing }) => (isEditing ? '20px' : 'unset'),
  },
  container: {
    display: 'flex',
    marginTop: '20px',
    rowGap: '20px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '10px',
    marginBottom: '3rem',
  },
  purple: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
}));

export const targetingStyles = makeStyles((theme: AdludioTheme) => ({
  column: {
    display: 'flex',
    rowGap: '20px',
  },
  purple: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
}));

export const SiteListStyles = makeStyles((theme: AdludioTheme) => ({
  purple: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  siteList: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  siteListGrid: {
    maxHeight: '6rem',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.5rem',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `${theme.palette.grey[500]}`,
      backgroundOpacity: 0.5,
      borderRadius: '4px',
    },
  },
  siteListTitle: {
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

export const campaignInfoStyles = makeStyles((theme: AdludioTheme) => ({
  column: {
    display: 'flex',
    rowGap: '20px',
  },
  form: {
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '15px',
  },
  dropdown: {
    minWidth: 120,
    width: '100%',
  },
  menuPaper: {
    maxHeight: '40vh',
  },
  otherPlacement: {
    marginTop: '20px',
  },
  helperText: {
    marginLeft: '0px',
  },
  placementError: {
    marginLeft: '0px',
    display: 'flex',
    columnGap: '5px',
    alignItems: 'center',
  },
  purple: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
}));

export const assetsUploadStyles = makeStyles((theme: AdludioTheme) => ({
  column: {
    display: 'flex',
    rowGap: '1.5rem',
  },
  helperText: {
    marginLeft: '0px',
  },
  purple: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  assetTitle: {
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
}));
