import React from 'react';

import {
  Card,
  Grid,
  Button,
  makeStyles,
  Box
} from '@adludio/components';
import FormCard from '../../Components/FormCard';

const useStyles = makeStyles({
  editView: {
    padding: '2rem 1.5rem',
  },
  editField: {
    maxWidth: '26rem'
  },
  editButtons: {
    maxWidth: '12rem'
  }
});

type ProfileEditProps = React.PropsWithChildren<{
  switchView: () => void,
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
}>

export default function ProfileEdit(props: ProfileEditProps){
  const styles = useStyles();
  return (
    <Card elevation={6}>
      <Box m='15px'>
        <FormCard handleSubmit={props.handleSubmit} handleDiscard={props.switchView}>
          <Grid container className={styles.editView}>
            <Grid
              item
              xs={12}
              className={styles.editField}
              direction='column'
              spacing={4}
            >
              <Grid
                item
                justify='space-around'
              >
                {props.children}
              </Grid>
              <Grid
                xs={8}
                className={styles.editButtons}
                container
                item
                wrap='nowrap'
                justify='space-between'
              >
                <Button onClick={props.switchView} size='large' variant='text'>Cancel</Button>
                <Button type='submit' size='large' variant='contained'>Save</Button>
              </Grid>
            </Grid>
          </Grid>
        </FormCard>
      </Box>
    </Card>
  );
}
