/* eslint-disable complexity */

import { Box, Divider, Grid, Tab, TabPanel, Tabs } from '@adludio/components';

import {
  NewCampaignFormContext,
  newCampaignFormContextDefaultValue,
} from '../../context/NewCampaignFormContext';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { CampaignBriefEdit } from '../../pages/CampaignBriefEdit';
import CheckIcon from '@material-ui/icons/Check';
import ViewCampaignBrief from '../../pages/ViewCampaignBrief';
import { newCampaignFormReducer } from '../../reducers/newCampaignFormReducer';
import { useGetCampaignTypeLazyQuery } from '../../generated/graphql';
import { CreativeCampaignForm } from '../Forms/Creative/CreativeForm';
import { ViewCreativeBrief } from '../../pages/ViewCreativeBrief';

const CampaignBrief = ({
  campaignId,
  briefSubmitted,
  setBriefSubmitted,
  creativeSubmitted,
  setCreativeBriefSubmitted,
  campaignStatus,
}: {
  campaignId?: string;
  briefSubmitted: boolean;
  setBriefSubmitted: Dispatch<SetStateAction<boolean>>;
  creativeSubmitted: boolean;
  setCreativeBriefSubmitted: Dispatch<SetStateAction<boolean>>;
  campaignStatus: string;
}) => {
  // STATES
  const [value, setValue] = React.useState(0);
  const [addCreativeBrief, setAddCreativeBrief] = useState(false);
  const [editCreative, setEditCreative] = useState(false);
  const [isMediaCampaign, setIsMediaCampaign] = useState<boolean | null>(null);

  const [state, dispatch] = useReducer(
    newCampaignFormReducer,
    newCampaignFormContextDefaultValue.state
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
  };

  const [fetch, { data }] = useGetCampaignTypeLazyQuery();
  const [editCampaignBrief, setEditCampaignBrief] = useState(
    !data?.getCampaignBrief?.isSubmitted
  );

  useEffect(() => {
    fetch({ variables: { campaignId: campaignId! } });
  }, [fetch, campaignId]);

  useEffect(() => {
    setEditCampaignBrief(!data?.getCampaignBrief?.isSubmitted);
  }, [data]);

  useEffect(() => {
    data?.getCampaignBrief &&
      setIsMediaCampaign(data.getCampaignBrief.isManaged!);
  }, [data]);

  return (
    <NewCampaignFormContext.Provider value={{ state, dispatch }}>
      <NewCampaignFormContext.Consumer>
        {(context) => (
          <Grid container justifyContent='center' direction='row'>
            <Grid container xs={12} justifyContent='center'>
              <Grid xs={10} md={10}>
                <Tabs value={value} onChange={handleChange}>
                  <Tab
                    label={
                      <Grid
                        container
                        direction='row'
                        alignItems='center'
                        justifyContent='center'
                      >
                        Campaign brief
                        {briefSubmitted && (
                          <Box ml='0.5rem'>
                            <CheckIcon />
                          </Box>
                        )}
                      </Grid>
                    }
                  />
                  {!isMediaCampaign && (
                    <Tab
                      label={
                        <Grid
                          container
                          direction='row'
                          alignItems='center'
                          justifyContent='center'
                        >
                          Creative brief
                          {creativeSubmitted && (
                            <Box ml='0.5rem'>
                              <CheckIcon />
                            </Box>
                          )}
                        </Grid>
                      }
                    />
                  )}
                </Tabs>
              </Grid>
            </Grid>
            <Grid xs={10}>
              <Divider style={{ width: '100%' }} />
            </Grid>
            <Grid
              container
              xs={12}
              justifyContent='flex-start'
              direction='column'
            >
              <TabPanel currentTab={value} index={0}>
                {!data?.getCampaignBrief?.isSubmitted || editCampaignBrief ? (
                  <CampaignBriefEdit
                    campaignId={campaignId!}
                    isMediaCampaign={isMediaCampaign}
                    setBriefSubmitted={setBriefSubmitted}
                  />
                ) : (
                  <ViewCampaignBrief
                    campaignId={campaignId}
                    setEditCampaignBrief={setEditCampaignBrief}
                    editCampaignBrief={editCampaignBrief}
                  />
                )}
              </TabPanel>
              {!isMediaCampaign && (
                <TabPanel currentTab={value} index={1}>
                  {addCreativeBrief || editCreative || !creativeSubmitted ? (
                    <Grid container justifyContent='center'>
                      <CreativeCampaignForm id={campaignId!} />
                    </Grid>
                  ) : (
                    <ViewCreativeBrief
                      campaignId={campaignId}
                      setAddCreativeBrief={setAddCreativeBrief}
                      setEditCreative={setEditCreative}
                    />
                  )}
                </TabPanel>
              )}
            </Grid>
          </Grid>
        )}
      </NewCampaignFormContext.Consumer>
    </NewCampaignFormContext.Provider>
  );
};

export default CampaignBrief;
