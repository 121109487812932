import {
  AdludioTheme,
  Box,
  Dropzone,
  Grid,
  makeStyles,
  Body1,
} from '@adludio/components';
import {
  FileType,
  Operation,
  UserType,
  useDeleteDocumentMutation,
  useEmailResolverLazyQuery,
  useUpdateUploadedStatusMutation,
} from '../../generated/graphql';
import React, { useEffect, useRef } from 'react';
import {
  useCreateDocumentMutation,
  useGetMagicLinkLazyQuery,
} from '../../generated/graphql';

import { CampaignDocument } from '../../models/document';
import { FileTable } from '../../components/FileTable';
import InternalCampaignPlan from '../../assets/upload-file.svg';
import { UploadStatus } from '../../components/UploadStatus';
import axios from 'axios';

interface InternalUserScreenProps {
  files: CampaignDocument[];
  campaignId: string;
  reFetch: () => void;
}
const useStyles = makeStyles((theme: AdludioTheme) => ({
  title: {
    fontWeight: 'bolder',
  },
  titleGrid: {
    paddingTop: '2rem',
  },
  fileTable: {
    minWidth: '83.3%',
    width: 'auto',
    maxWidth: '100%',
  },
  pageContent: {
    marginBottom: '2.5rem',
  },
}));

export const InternalUserScreen = (
  props: InternalUserScreenProps
): JSX.Element => {
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [files, setFiles] = React.useState<File[]>([]);
  const filesRef = useRef(files);
  const [filesAwaiting, setFilesAwaiting] = React.useState<File[]>([]);
  const filesAwaitingRef = useRef(filesAwaiting);
  const [status, setStatus] = React.useState<boolean[]>([]);
  const [uploadProgress, setUploadProgress] = React.useState<number[]>([]);
  const uploadProgressRef = useRef(uploadProgress);

  const [currentProgress, setCurrentProgress] = React.useState<number>(0);
  /* duplicated hook , one for downloading , the other for uploading */
  const [getMagicLink, { data: linkData }] = useGetMagicLinkLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [prevLink, setPrevLink] = React.useState<string>();
  const [isUploading, setIsUploading] = React.useState(false);
  const [createDocumentMutation] = useCreateDocumentMutation();
  const [sendEmail] = useEmailResolverLazyQuery({
    variables: {
      campaignId: props.campaignId,
      templateName: 'CampaignPlanCreated',
      userType: UserType.External,
    },
  });
  const { titleGrid, fileTable, pageContent } = useStyles();
  const [currentDocument, setCurrentDocument] = React.useState<string>('');
  const [deleteDocumentMutation] = useDeleteDocumentMutation();

  const [updateUploadStatus] = useUpdateUploadedStatusMutation();
  const cancelTokenSource = useRef(axios.CancelToken.source());
  const intervalRef = useRef<number | null>();

  /* MIME types for PDF, PowerPoint and every Excel type */
  const acceptedFileType: string = '';

  const handleFiles = (droppedFiles: File[]) => {
    const sorted = [...droppedFiles].sort((a, b) => a.size - b.size);
    if (filesAwaiting.length) {
      setFiles(files.concat(sorted));
      setUploadProgress(
        uploadProgress.concat(Array(droppedFiles.length).fill(0))
      );
    } else {
      setFiles(sorted);
      setCurrentProgress(0);
      setUploadProgress(Array(droppedFiles.length).fill(0));
      setStatus([]);
    }
    setFilesAwaiting(filesAwaiting.concat(sorted));
  };

  const handleFileUpload = (file: File) => {
    const fileType = setFileType(file.name);
    setSelectedFile(file);
    createDocumentMutation({
      variables: {
        campaignId: props.campaignId,
        fileName: file.name,
        fileType,
      },
    })
      .then((newDocument) => {
        setCurrentDocument(newDocument.data!.createDocument!.id!);
        getMagicLink({
          variables: {
            fileId: newDocument.data!.createDocument!.id!,
            type: fileType,
            campaignId: props.campaignId,
            operation: Operation.Put,
          },
        });
        if (fileType === FileType.CampaignPlan) sendEmail();
      })
      .catch(console.log);
  };

  const handleUploadComplete = () => {
    const selectedFileIndex = files.indexOf(selectedFile!);
    const newUploadProgress = [...uploadProgressRef.current];
    newUploadProgress.splice(selectedFileIndex, 1, 100);
    setUploadProgress(newUploadProgress);
    updateUploadStatus({ variables: { id: currentDocument } })
      .then((data) => {
        setTimeout(() => {
          props.reFetch();
        }, 1000);
      })
      .catch(console.log);
    setStatus(status.concat(true));
  };

  const setFileType = (fileName: string) => {
    let fileType = FileType.Storyboard;
    /* checking if it contains "xl" for any type of Excel extension */
    if (fileName.split('.').pop()?.includes('xl'))
      fileType = FileType.CampaignPlan;
    return fileType;
  };

  const removeUpload = (index: number) => {
    const fileToBeRemoved = filesRef.current[index];
    const newFiles = [
      ...filesRef.current.slice(0, index),
      ...filesRef.current.slice(index + 1),
    ];
    setFiles(newFiles);
    const newUploads = [
      ...uploadProgressRef.current.slice(0, index),
      ...uploadProgressRef.current.slice(index + 1),
    ];
    setUploadProgress(newUploads);
    if (selectedFile === fileToBeRemoved) {
      cancelTokenSource.current.cancel();
    } else {
      setFilesAwaiting(
        filesAwaitingRef.current.filter(
          (awaitingFile) => awaitingFile !== fileToBeRemoved
        )
      );
    }
  };

  const uploadCleanUp = () => {
    if (selectedFile && filesAwaitingRef.current.length > 1) {
      const currentFileIndex = filesRef.current.indexOf(selectedFile);
      setCurrentProgress(uploadProgressRef.current[currentFileIndex + 1]);
    } else {
      setCurrentProgress(0);
    }
    setPrevLink(linkData?.getMagicLink!);
    setIsUploading(false);
    setFilesAwaiting(filesAwaitingRef.current.slice(1));
  };

  const handleUploadError = (error: any) => {
    console.log(error);
    if (!axios.isCancel(error)) {
      setStatus(status.concat(false));
    }
    deleteDocumentMutation({
      variables: { id: currentDocument },
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    if (
      !isUploading &&
      filesAwaiting.length &&
      selectedFile !== filesAwaiting[0]
    ) {
      const file = filesAwaiting[0];
      cancelTokenSource.current.cancel();
      cancelTokenSource.current = axios.CancelToken.source();
      handleFileUpload(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesAwaiting, isUploading]);

  useEffect(() => {
    filesAwaitingRef.current = filesAwaiting;
  }, [filesAwaiting]);

  useEffect(() => {
    uploadProgressRef.current = uploadProgress;
  }, [uploadProgress]);

  useEffect(() => {
    filesRef.current = files;
  }, [files]);

  const fakeUploadProgress = async () => {
    const newUploadProgress = [...uploadProgressRef.current];
    filesAwaitingRef.current.forEach((file, i) => {
      if (i === 0) return;
      const fileIndex = filesRef.current.indexOf(file);
      const fakeProgress =
        uploadProgressRef.current[fileIndex] + Math.random() * 0.5;
      if (fakeProgress > 5) return;
      newUploadProgress.splice(fileIndex, 1, fakeProgress);
    });
    setUploadProgress(newUploadProgress);
  };

  useEffect(() => {
    if (filesAwaiting.length) {
      intervalRef.current = window.setInterval(function () {
        fakeUploadProgress().catch(console.log);
      }, 4000);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesAwaiting]);

  React.useEffect(() => {
    if (selectedFile) {
      if (
        filesAwaiting.length &&
        linkData &&
        linkData.getMagicLink! !== prevLink
      ) {
        setIsUploading(true);
        axios
          .put(linkData.getMagicLink!, selectedFile, {
            maxBodyLength: Infinity,
            cancelToken: cancelTokenSource.current.token,
            onUploadProgress: (progressEvent: any) => {
              const currentFileIndex = files.indexOf(selectedFile);
              const fakeProgress = uploadProgressRef.current[currentFileIndex];
              let percentComplete =
                (progressEvent.loaded / progressEvent.total) * 100;
              let newProgress =
                fakeProgress > percentComplete ? fakeProgress : percentComplete;
              const newUploadProgress = [...uploadProgressRef.current];
              newUploadProgress.splice(currentFileIndex, 1, newProgress);
              setUploadProgress(newUploadProgress);
            },
          })
          .then(() => handleUploadComplete())
          .catch((error: any) => handleUploadError(error))
          .finally(() => uploadCleanUp());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile, linkData]);

  return (
    <Grid
      className={pageContent}
      container
      item
      xs={12}
      direction='row'
      justifyContent='center'
      alignItems='center'
    >
      <Grid
        item
        container
        lg={10}
        md={10}
        sm={12}
        direction='row'
        justifyContent='flex-start'
        className={titleGrid}
      >
        <Body1>Campaign Plan Documents</Body1>
      </Grid>
      <Grid item container lg={10} md={10} sm={12}>
        <Box width='100%' paddingY='2rem' minHeight='8rem' textAlign='center'>
          <Dropzone
            numberOfFiles='multiple'
            setFiles={handleFiles as any} // Dropzone component expects a setState but we're handling it differntly
            files={files}
            accept={acceptedFileType}
          />
          <UploadStatus
            files={filesRef.current}
            status={status}
            progress={uploadProgress}
            currentProgress={currentProgress}
            currentIndex={selectedFile ? files.indexOf(selectedFile) : 0}
            removeUpload={removeUpload}
          />
        </Box>
      </Grid>
      <Grid
        item
        container
        className={fileTable}
        justifyContent='center'
        alignItems='center'
      >
        {props.files.length > 0 ? (
          <FileTable
            data={props.files}
            includesStatus
            reFetch={props.reFetch}
          />
        ) : (
          <Grid container justifyContent='center'>
            <Box width={'100%'}>
              <Body1 color='primary' align='center'>
                No files have been uploaded yet.
              </Body1>
              <Body1 color='primary' align='center'>
                To get started, drag and drop or browse files using the drop
                zone
              </Body1>
            </Box>
            <Box mt='3rem'>
              <img src={InternalCampaignPlan} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
